<template>
	<div>
		<b-card no-body>
			<b-card-header class="formCardHeader" id="formHeader">
				<div class="d-flex justify-content-between mb-1 align-items-end">
					<div class="mt-1">
						<h4 :class="floatClass != null ? floatClass : ``" v-text="sectionTitle"></h4>
						<div><slot name="subtitleSlot"></slot></div>
					</div>

					<div class="d-flex flex-row">
						<slot name="buttonSlot"></slot>
						<div v-show="!hideSidebar" class="align-self-center ml-2" v-if="hideCreation == 0">
							<b-button class="btn-icon bg-light" size="sm" v-b-toggle.master_form_sidebar_right>
								<b-icon-chevron-double-left></b-icon-chevron-double-left>
							</b-button>
						</div>
					</div>
				</div>
				<slot name="alertMessageSlot"></slot>
				<v-overlay :value="overlay">
					<v-progress-circular indeterminate size="64"></v-progress-circular>
				</v-overlay>
			</b-card-header>
			<b-card-body :style="!$CoreSettingsJs.emptyStringValidation(bodyHeight, 1) ? `height: ${bodyHeight}px !important; padding-top: 5px !important;` : `padding-top: 5px !important;`">
				<slot name="formDataSlot"></slot>
			</b-card-body>
			<b-card-footer v-if="hideFooter == 0" id="formFooter" class="d-flex justify-content-end">
				<div class="d-flex flex-row">
					<slot name="footerButtonSlot"></slot>
					<div v-show="!hideSidebar" class="align-self-center ml-2" v-if="hideCreation == 0">
						<b-button class="btn-icon bg-light" size="sm" v-b-toggle.master_form_sidebar_right>
							<b-icon-chevron-double-left></b-icon-chevron-double-left>
						</b-button>
					</div>
				</div>
			</b-card-footer>
		</b-card>
		<b-sidebar bg-variant="white" id="master_form_sidebar_right" width="180px" right shadow no-header>
			<template #default="{ hide }">
				<div class="px-3 py-2">
					<div class="d-flex flex-column">
						<div class="text-right">
							<b-button size="sm" variant="danger" @click="hide">
								<span v-text="$t('COMMON.BTN_CLOSE')"></span>
							</b-button>
						</div>
						<slot name="creationModifiedSlot"></slot>
						<slot name="statusSlot"></slot>
					</div>
				</div>
			</template>
		</b-sidebar>
	</div>
</template>

<script>
export default {
	name: "MasterDataForm",
	props: {
		sectionTitle: String,
		floatClass: null,
		hideFooter: {
			type: Number, // not 'number'
			required: false,
			default: 1
		},
		hideCreation: {
			type: Number, // not 'number'
			required: false,
			default: 0
		},
		bodyHeight: {
			type: Number,
			default: null
		},
		hideSidebar: {
			type: Boolean,
			default: false
		},
		overlay: {
			type: Boolean,
			default: false
		}
	}
};
</script>
