export const locale = {
	WEEKDAYS: {
		MONDAY: "星期一",
		TUESDAY: "星期二",
		WEDNESDAY: "星期三",
		THURSDAY: "星期四",
		FRIDAY: "星期五",
		SATURDAY: "星期六",
		SUNDAY: "星期日",
	},
	COMMON: {
		LBL_AGENT: "GBizs 代理",
		LBL_MOBILE_CONTACT_NO: "手机联系号",
		LBL_MOBILE_CONTTACT_PIC: "手机联系人",

		LBL_ALT_MOBILE_CONTACT_NO: "手机联系号 (外加)",
		LBL_ALT_MOBILE_CONTTACT_PIC: "手机联系人 (外加)",

		LBL_OFFICE_NO: "办公室联络号 ",
		LBL_OFFICE_NO_PIC: "办公室联络人",
		LBL_SET_INVALID_PERSONNEL: "Invalid By",
		LBL_APPROVED_BY: "Approved By",
		LBL_REJECTED_BY: "Rejected By",
		LBL_REVERTED_BY: "Reverted By",
		LBL_ACKNOWLEDGE_BY: "Acknowledge By",
		LBL_VERIFY_BY: "Verify By",
		LBL_ACTION_TIME: "Action Time",
		LBL_REFERENCE_NO: "单号",
		LBL_HISTORY_DATE: "修改日期",
		LBL_PARCEL_GUARANTEE: "货物保障",
		LBL_AR_REMARK: "AR 备注",
		LBL_AP_REMARK: "AP 备注",
		LBL_RECEIVED_AMOUNT: "收货金额",
		LBL_MARKING: "客户代号",
		LBL_SO_NO: "S/O No.",
		LBL_NOTICE_TYPE: "Notice Type",
		LBL_EMAIL: "邮址",
		LBL_TIN_NO: "Tin No.",
		LBL_CATEGORY_TYPE: "类别类型",

		LBL_FAX_NO: "传真号",
		LBL_FAX_NO_PIC: "传真联系人",
		LBL_SERIAL_NO: "序列",

		TXT_ALL: "全部",
		TOTAL_TITLE: "总额",
		TXT_RANGE: "{from} - {to} 之间",
		TXT_ABV: ">= {from}",
		TXT_BELOW: "<= {to}",

		SELECTION_OTHERS: "其他",
		PH_ENTER_TEXTBOX: "输入 {txt_fieldname}",
		PH_ENTER_DATE: "输入日期",
		PH_ENTER_DATE_FROM: "输入 {name} 从",
		PH_ENTER_DATE_TO: "输入 {name} 至",
		MSG_SUPPORT_IMAGE_SIZE_FORMAT:
			"图片大小应小于1 MB,支持的格式为.jpg、.jpeg、.png",
		MSG_IMAGE_FORMAT_NOT_SUPPORTED:
			"图像格式不支持。支持的格式: .jpg, .jpeg, .png",
		MSG_SUPPORT_DOC_SIZE_FORMAT:
			"文档大小应小于10 MB",
		MSG_ROW_ERROR: "Row: {idx}",

		STATUS: {
			INACTIVE: "不活跃",
			ACTIVE: "活跃",
			SUSPEND: "暂停",
			UNVERIFIED: "未核实",
			YES: "是",
			WGI: "WGI",
			NO: "不是",
			DRAFT: "草稿",
			PENDING: "待定",
			APPROVED: "已审核",
			REJECTED: "已拒绝",
			VERIFY: "核实中",
			CLOSED: "已关闭",
			OPEN: "打开",
			ACKNOWLEDGE: "确认",
			NEW: "新",
			EXPIRE: "到期",
			VALID: "Valid",
			LOCKED: "禁用",
			REJECT: "拒绝",
			REVERT: "还原",
			VERIFIED: "已核实",
			BLOCKED: "阻止"
		},


		MSG: {
			API_SERVER_DOWN: "服务器连接被拒绝。请联系系统管理员。",
			MSG_DATA_NOT_FOUND: "未找到记录",
			DATA_NOT_AVAILABLE: "无可用数据",
			SUCCESSFUL_IMPORT_EXCEL: "已成功导入Excel",
			VERSION_NOT_MATCH: "数据已修改，请重新加载。",
		},

		VALIDATION: {
			INVALID: "{name}无效",
			REQUIRED: "{name}为必填项。",
			REQUIRED_GREATER_ZERO: "{name}必须大于零",
			MIN_LENGTH: "{name}最小长度是{min}",
			AGREEMENT_REQUIRED: "需要接受条款和条件",
			NOT_FOUND: "{name}找不到请求",
			REF_NOT_FOUND: "单号: {ref_no}找不到",
			INVALID_LOGIN: "登录详细信息不正确",
			REQUIRED_FIELD: "必填字段",
			MIN_LENGTH_FIELD: "最小字段长度：",
			MAX_LENGTH_FIELD: "最大字段长度：",
			INVALID_FIELD: "字段无效",
			NUMBER: "{name}必须是数字",
			CANNOT_EQUAL: "{p1}不能相同{p2}",
			EXPIRE: "{name}必须大于当前日期",

			MISSING_REQ_DATA: "请填写所有详细信息",
			INVALID_WARNING_MSG: "请提供正确的数据！",
			DUPLICATE_ENTRY: "{name}重复。请重试",
			UNIQUE_FIELD: "{name}必须是独特的",
			DOCUMENT_NUMBERING_NOT_SET: "单号格式找不到. 请先设置",
			DOC_NUMBER_NOT_SET_TYPE: "{doc_type}单号格式找不到. 请先设置",
			ID_NOT_FOUND: "未找到相关数据",
			MISSING_DOC_TYPE: "单号类型: {doc_type}找不到. 请联系系统管理员。",
			UNIQUE_VALUE: "{code} 不可用",
			MARKING_NOT_EXISTS: "客户代号不存在",
			DOCUMENT_NO_DUPLICATE: "单号复制",
			DATE_FROM_GREATER_THAN_DATE_TO: "{date_to}必须大过{date_from}",
			DATA_NOT_FOUND_REQ_CREATE: "{code}无法找到。请创建新记录",

			INVALID_CONTACT_NO_FORMAT: {
				FORMAT_MY: "{name}格式错的, 正确的格式 eg: 123456789"
			},
			POSITIVE: "{name} 只允许输入正数",
			RECORD_NO_SELECTED: "尚未选择任何数据",
			INVALID_DATE_FORMAT_EXCEL: "{name} 不支持日期格式",
			ROW_DATA_NOT_FOUND: "Row: {idx}未找到相关数据",
			DUPLICATE_RECORD_SET: "重复设置",
			DOCUMENT_FORMAT_LENGHT: "No. Format cannot more than {len} character",
			DOCUMNET_NUMBER_FORMAT_DUPLICATE_SET: "No.Format: {format} duplicate set",
			EXCEL_FILE_NOT_UPLOAD: "Please upload product excel file",
			EXCEL_FILE_TYPE_INVALID: "Excel File Type Invalid",
			EXCEL_IMPORT_DATA_NOT_FOUND: "Uploaded Data not found",
			EXCEL_DATA_INCORRECT: "Please ensure uploaded data is correct",

		},

		BTN_DOWNLOAD_ALL_AS_PDF: "全部下载为PDF",
		BTN_DOWNLOAD_AS_PDF: "下载为PDF",
		BTN_REJECT: "拒绝",
		BTN_VERIFY: "核实",
		BTN_SEARCH: "搜索",
		BTN_CLEAR: "清除",
		BTN_CANCEL: "取消",
		BTN_BACK: "返回",
		BTN_SAVE: "保存",
		BTN_BROWSE: "浏览",
		BTN_EDIT: "编辑",
		BTN_REVERT: "还原",
		BTN_CREATE: "新建{btn_name}",
		BTN_SUBMIT: "提交",
		BTN_APPROVE: "批准",
		BTN_ADD: "添加",
		BTN_DELETE: "删除",
		BTN_COPY: "复制",
		BTN_CLOSE: "关闭",
		BTN_CONFIRM: "确认",
		BTN_ACKNOWLEDGE: "同意",
		BTN_PREVIEW: "预览",
		BTN_SAVE_AS_DRAFT: "另存为草稿",
		BTN_PRINT: "打印",
		BTN_PRINT_ALL: "全部打印",
		BTN_CONFIRM_N_APPROVE: "确认并批准",
		BTN_VIEW: "查看",
		BTN_RESET: "重置",
		BTN_DOWNLOAD_ALL: "全部下载",
		BTN_DOWNLOAD_SELECTED: "下载所选",
		BTN_COST_SETUP: "Cost Setup",
		BTN_LEAVE: "离开页面",
		BTN_STAY: "留在页面上",
		BTN_REFRESH: "刷新",
		BTN_OK: "好的",
		BTN_UPLOAD: "上传",
		BTN_IMPORT: "导入Excel",
		BTN_POST: "Post",
		BTN_REVERSE: "撤销",
		BTN_SYNC_ALL: "全部同步",
		BTN_SYNC_ALL_WAREHOUSE: "全部同步至仓库",
		BTN_SYNC_ALL_AGENT: "全部同步至代理",
		BTN_PREVIEW_IMAGE: "预览图像",
		BTN_PREVIEW_DOC: "预览文档",
		BTN_UPLOAD_IMAGE: "上传图片",
		BTN_UPLOAD_DOC: "上传文件",
		BTN_ALL: "全部",
		BTN_HISTORY: "历史",
		BTN_SYNC_TO_AGENT: "同步至代理",
		BTN_SYNC_TO_WAREHOUSE: "同步至仓库",
		BTN_NEW: "新",
		BTN_SKIP: "跳过",
		BTN_ENABLE_EDIT: "启用编辑",
		BTN_DISABLE_EDIT: "关闭编辑",
		BTN_COPY_EDIT: "复制/编辑",
		BTN_IGNORE: "忽略",
		BTN_SELECT: "选择",
		BTN_LINK: "链接",
		BTN_REMOVE: "祛除",
		BTN_DROP_FILE: "点击或拖拽{file}文件到该区域即可上传",
		BTN_CLICK_HERE: "点击这里",
		BTN_YES: "确定",
		BTN_NO: "拒接",

		BTN_MANUAL: "手动",
		BTN_AUTO: "自动",

		FILE_TYPE: {
			EXCEL: "Excel"
		},

		MSG_SUPPORT_EXCEL_FILE: "支持的文件类型: .xlsx, .xls",
	},
	MENU: {
		ASIDE: {
			CATEGORY_PARENT: "产品类别（父级）",
			CATEGORY: "产品类别 (2 级)",
		}
	},
	AUTH: {
		BTN_SIGN_OUT: "登出",
		USERNAME_TITLE: "嗨, {username}",
	},
	EPO: {
		FORMAT_CN: "中文版",
		FORMAT_EN: "英文版",
		FORMAT_IN: "印尼文版",
		FORMAT_TH: "泰文版",
		FORMAT_VN: "越南文版",

		DOWNLOAD_EPO_IMPORT_EXCEL_FORMAT: "下载导入产品Excel格式",
		BTN_IMPORT_PROD: "导入产品",
		UPLOAD_RPODUCT_TITLE: "导入产品Excel文档·",

		MSG: {
			EXCEL_SHEET_NOT_FOUND: "{name} Sheet not found in excel file",
			EXCEL_FORMAT_NOT_FOUND: "{name} Sheet Column not matching!",
			PARCEL_GUARANTEE_ADD_CHARGES_CUSTOMER: "需额外收取货物保障费用，请联系负责人",
			PARCEL_GUARANTEE_ADD_CHARGES_ADMIN: "需额外收取货物保障费用，请与客户确认",
			PARCEL_GUARANTEE_CONFIRMATION: "需额外收取货物保障费用。您确定要继续吗？",
		},
		BTN_ADD_PROD: "添加商品",
		LBL_HAS_TAX_REBATE: "退税"
	},
	CUSTOMER: {
		REQUEST_TYPE: {
			CREATE_TYPE: "新建",
			UPDATE_TYPE: "更新",
		},
		LBL_SAVE_DELIVERY_ADDRESS: "另存为送货地址",
	},
	TT: {
		BENE_MODEL: {
			BENE_TITLE: "Beneficiary Details",
			INTERMEDIATE_TITLE: "Intermediate Bank Details",
			INTERMEDIATE_CHECKBOX: "Intermediate Bank?",
			ACC_HOLDER: "Account Holder",
			ACC_NO: "Account Number",
			BENE_CONTACT_PIC: "Bene Contact PIC",
			BENE_CONTACT_CALLING_CODE: "Bene Calling Code",
			BENE_CONTACT_NO: "Bene Contact Number",
			RI_NO: "Ri Number",
			BANK_NAME: "Bank Name",
			BANK_CITY: "Bank City",
			BANK_COUNTRY: "Bank Country",
			BANK_ADDRESS: "Bank Address",
			BENE_ADDRESS: "Bene Address",
			BANK_REMARK: "Bank Remark",
			BANK_CODE: "BIC / Swift Code",
			IBAN: "IBAN",
			INTER_BANK_NAME: "Intermediate Bank Name",
			INTER_BANK_ACC: "Intermediate Account No",
			INTER_SWIFT_CODE: "Intermediate BIC / Swift Code",
			INTER_IBAN_CODE: "Intermediate IBAN Code",
			BENE_DETAIL_NAME: "Beneficiary Detail Name"
		},
	},
	GRN_FORMAT: {
		HEADER: {
			DATE: "日期",
			SUPPLIER: "供应商",
			SUPPLIER_CODE: "供应商代码",
			REMARK: "备注",
			MOBILE: "手机",
			CONTACT_PERSON: "联系人",
			DOC_NO: "单号",
			PHONE_NO: "电话",
			PAYMENT_METHOD: "付款方式",
			RECEIPT: "收据",
			SHARED_SO: "共单号",
		},
		CONTENT: {
			SERIAL_NO: "序号",
			CUSTOMER_MARKING: "客户代号",
			SO_NO: "订单号",
			CTN: "件数",
			WEIGHT: "重量",
			DIMENSION: "体积",
			OTHER: "其他",
			PN: "PN",
			TOTAL: "总额"
		},
		FOOTER: {
			DELIVER: "送货人签名",
			PERSON_IN_CHARGES: "经手人",
			CONTACT_PERSON: "联系人",
			CONTACT_NO: "电话"
		}
	},
	PN_FORMAT: {
		HEADER: {
			DATE: "日期",
			SUPPLIER: "供应商",
			SUPPLIER_CODE: "供应商代码",
			REMARK: "备注",
			MOBILE: "手机",
			CONTACT_PERSON: "联系人",
			DOC_NO: "单号",
			PHONE_NO: "电话",
			PAYMENT_METHOD: "付款方式",
			ACCOUNT_NAME: "账户名",
			ACCOUNT_NO: "账号",
			BANK_ADDRESS: "开户行",
			BANK_NAME: "银行名"
		},
		CONTENT: {
			SERIAL_NO: "序号",
			CUSTOMER_MARKING: "客户代号",
			SO_NO: "订单号",
			CURRENCY: "币种",
			CTN: "件数",
			WEIGHT: "重量",
			DIMENSION: "体积",

			TOTAL_AMT: "总金额",
			DISCOUNT: "折扣",
			DEPOSIT: "订金",
			STICKER_FEE: "扣贴纸费",
			BALANCE_AMT: "余额",
			TOTAL: "总额"
		},
		FOOTER: {
			DELIVER: "送货人签名",
			PERSON_IN_CHARGES: "经手人",
			CONTACT_PERSON: "联系人",
			CONTACT_NO: "电话"
		}
	},
	REPORT: {
		PACKING_LIST_FORMAT: {
			TITLE: "Packing List Details (C) New",
			SO_NO: "S/O No.",
			SUPPLIER_NAME: "Sup Name",
			T_M3: "T/M3<br />(T/CM3)",
			T_KG: "T/Kg<br />(Vol Weight)",
			T_QTY: "T/Pqty",
			BILL_AMT: "Bill Amt",
			LOADED_AMOUNT: "Loaded Amt",
			CUS_DEPOSIT: "Cus Deposit",
			MY_PAYMENT: "My P'ment",
			FCP_AMT: "FCP Amount",
			SUP_DEPOSIT: "Sup Deposit",
			LOCAL_PAYMENT: "Local P'ment",

			STOCK_DESC: "Stock Short Desc",
			M3: "M3",
			KG: "Kg",
			P_QTY: "P Qty",
			QUANTITY: "Quantity",
			UOM: "Uom",
			UNIT_PRICE: "Unit Price",
			AMOUNT: "Amount",
			RECEIVE_DATE: "Received Date",
			REF_ID: "Ref ID",
			MAIN_SALESMAN: "Main Salesman",
			SALESMAN: "Salesman",
			CUSTOMER_MARKING: "Marking",
			DELIVERY_ID: "Delivery Id",

			REPORT_FILTER: "Report Filter",
			SHOW_M3: "Show M3?",
			SHOW_KG: "Show Kg?",
			SHOW_BILL_AMOUNT: "Show Bill Amount?",
			SHOW_STOCK_DESC1: "Show Stock Desc 1?",
			SHOW_STOCK_DESC2: "Show Stock Desc 2?",
			SHOW_SUPPLIER: "Show Supplier",
			YES: "Yes",
			NO: "No",
			CURRENCY_ID: "Currency ID",
			REMARK: "Remark",
			DEPOSIT_DATE: "D. Date",
			DEPOSIT_NO: "PN #",
			DEPOSIT_RATE: "TR",
			DEPOSIT_AMOUNT: "TS Amt",
			DEPOSIT_CHARGE: "FTC",
			DEPOSIT_CHARGE_MYR: "TC (RM)",
			DEPOSIT_IR: "IR %",
			DEPOSIT_OR: "AOR No.",

			SUB_TTL_T_M3: "T/M3",
			SUB_TTL_T_KG: "T/Kg",
			SUB_TTL_T_PQTY: "T/Pqty",
			SUB_TTL_REF: "Ref ",
			SUB_TTL_VOL_WEIGHT: "Vol Weight",
			SUBTOTAL: "SubTotal",
			CURRENCY: "Currency"
		},
		STOCK_BALANCE_FORMAT: {
			TITLE: "Stock Balance Report with Payment Info",
			DETAIL: "Detail",
			SUMMARY: "Summary",
			SERIAL_NO: "S/N",
			CURRENCY_ID: "Cur ID",
			SO_NO: "S/O No.",
			RECEIVE_DATE: "Rec Date",
			NOTICE_ID: "Notice ID",
			TOTAL_M3: "Total M3",
			TOTAL_PACK: "Total Pack",
			BAL_AMT: "Balance Amount",
			CUS_DEPOSIT: "Cust Deposit",
			MY_PAYMENT: "My Payment",
			SUP_DEPOSIT: "Sup Deposit",
			CASH_PAYMENT: "Cash P'ment",
			CREDIT_PAYMENT: "Credit P'ment",
			FCP_AMOUNT: "FCP Amount",
			PAYABLE_AMT: "Payable Amt",

			PO_DATE: "PO Date",
			STOCK_SHORT: "Stock Short",
			STOCK_DESC: "Stock Desc",
			M3: "M3",
			BAL_PACK: "Bal Pack",
			QUANTITY: "Quantity",
			PURCHASE_PRICE: "Purchase Price",
			AMOUNT: "Amount",
			MAIN_SALESMAN: "Main Salesman",
			SALESMAN: "Salesman",
			CUSTOMER_MARKING: "Marking",
			DELIVERY_ID: "Delivery Id",
			OR_DATE: "O/R Date",
			LOADED_DATE: "Loaded Date",
			// REPORT_FILTER: "All / KCG Only / Exclude KCG",
			FROM: "From",
			TO: "To",
			REPORT_FILTER: "Report Filter",
			REPORT_OPTIONS: "Report Options",
			DELIVERY_ID_TOTAL: "Delivery ID Total",
			MARKING_TOTAL: "Marking Total",
			SALESMAN_TOTAL: "Salesman Total",
			MAIN_SALESMAN_TOTAL: "Main Salesman Total",
			CURRENCY_TOTAL: "Currency Total"
		},
		DO_FORMAT: {
			FORMAT_TITLE: "DELIVERY ORDER",
			TEL_NO: "Tel No.",
			GOODS_RECEIVE_CONDITION: "Goods received in good order & condition",
			SIGNATURE_TITLE: "Authorised Signature & Co Stamp",
			FOOTER_E_DESC: "Computer Generated, no signature required",
			FOOTER_END: "== End ==",

			DONO: "D/Order #",
			REF_ID: "Yr Ref",
			DO_DATE: "Date",
			TRANPORTER: "T-Ref",
			PAGE: "Page",
			MOBILE_NO: "Mobile No.",
			FORWARDER: "Forwarder",
			FAX: "Fax",
			REMARK1: "Remark1",
			REMARK2: "Remark2",
			SERIAL_NO: "S/N",
			SO_NO: "",
			DESCRIPTION: "Description",
			QUANTITY: "Quantity",
			UNIT: "Unit",
			PKG: "Pkg",
			QTY: "Qty",
			TOTAL_M3: "Total M3",
			TOTAL_KG: "Total KG",
			TOTAL_PACKING: "Total Packing",
		}
	},
	MASTER_DATA: {
		DELIVERY_AGENT: {
			MODULE_NAME: "送货代理",
			LISTING_TITLE: "送货代理列表",
			CREATE_TITLE: "创建送货代理",
			DETAIL_TITLE: "送货代理详情",
			EDIT_TITLE: "编辑送货代理",
			CODE: "代理编码",
			NAME: "代理名",
			LABEL: "标签",

			LBL_COURIER: "快递",
			LBL_COURIER_LIST: "支持快递",

			MSG_COUNTRY_COURIER_NOT_SET: "{name} 快递未创建."
		},
	},
	PRODUCT_MANAGEMENT: {

		CATEGORY_PARENT: {
			LISTING_TITLE: "产品类别 (父级) 列表",
			CREATE_TITLE: "Create New 产品类别 (父级)",
			DETAIL_TITLE: "产品类别 (父级) 详情",
			EDIT_TITLE: "Edit 产品类别 (父级) 详情",

			LBL_CATEGORY_CODE: "产品类别 Code",
			LBL_CATEGORY_NAME: "产品类别 Name",
			LBL_CATEGORY_PARENT: "产品类别 父级",

			MODULE_NAME: "产品类别",

			COL_CATEGORY_CODE: "产品类别 Code",
			COL_CATEGORY_NAME: "产品类别 名称",
			COL_CATEGORY_PARENT: "产品类别 父级",
			COL_SERVICE_CHARGES: "服务费?",
			ROOT_SELECTION: "父级"
		},
		CATEGORY: {
			LISTING_TITLE: "产品类别 (2级) 列表",
			CREATE_TITLE: "Create New 产品类别 (2级)",
			DETAIL_TITLE: "产品类别 (2级) 详情",
			EDIT_TITLE: "Edit 产品类别 (2级) 详情",

			LBL_CATEGORY_CODE: "产品类别 Code",
			LBL_CATEGORY_NAME: "产品类别名称",
			LBL_CATEGORY_PARENT: "产品类别(父级)",
			LBL_INVOICE_DESC: "发票说明",
			LBL_CHARGES_FLAT: "服务费",

			MODULE_NAME: "产品类别",

			COL_CATEGORY_CODE: "产品类别Code",
			COL_CATEGORY_NAME: "产品类别名称",
			COL_CATEGORY_PARENT: "产品类别(父级)",
			COL_SERVICE_CHARGES: "服务费?",
			ROOT_SELECTION: "父级",
			MSG_CATEGORY_DUPLICATION: "{code} is not available."
		},
	},
	PN_PAYMENT: {
		MODULE_NAME: "欠单付款",
		LBL_BADGE_NO: "Badge No.",
		LBL_DATE: "付款日期",

		SO_TITLE: "S/O详情",
		BENE_TITLE: "收款人/银行详情",

		SHOW_PAYABLE_DETAILS: "显示 PN 详细信息",
		SHOW_SO_BENE: "Show S/O Bene & Account Detail",

		LBL_DUE_DAYS: "到期日期",

		COL_SUPPLIER_DEPOSIT: "S/O Sup Deposit",
		COL_CUSTOMER_DEPOSIT: "S/O Cus Deposit",
		COL_CASH_PAYMENT: "Cash Payment",
		COL_WAREHOUSE_DISCOUNT: "Sup Wh Disc",
		COL_DISCOUNT: "折扣金额",
		COL_MY_PAID: "My Paid",
		COL_STICKER_FEES: "贴纸费用",
		COL_STICKER_FEES_PAID_AMOUNT: "厂家已付金额",
		COL_PAYABLE_AMOUNT: "欠单金额",
		COL_PAID_AMOUNT: "已付欠单金额",
		COL_PN_CANCEL_AMOUNT: "取消欠单金额",
		COL_BALANCE_PN_AMOUNT: "欠单余额",
		COL_PN_PAYMENT_AMOUNT: "欠单付款金额",
		COL_BANK_ACC_NAME: "Acc. Holder",
		COL_BANK_ACC: "Acc No.",
		COL_BANK_NAME: "Bank Name",
		COL_BANK_PROVINCE: "Bank Province",
		COL_BANK_DISTRICT: "Bank District",
		COL_BANK_ADDRESS: "Bank Address",
		COL_PN_REF_NO: "欠单付款单号",

		APPROVE_SUMMARY: "欠单付款列表",
		MODULE_NAME_APPROVE: "审批欠单付款",

		PN_PAYMENT_DETAIL_TITLE: "欠单付款详情",
		APPROVE_PN_CONFIRMATION: "后您将无法编辑此Badge No.: {badge_no}。",
		MODULE_NAME_ACKNOWLEDGE: "Acknowledge PN Payment",

	},
	BANK_BENE: {
		LBL_ACCOUNT_NAME: "银行账户名",
		LBL_ACCOUNT_NO: "银行帐号",
		LBL_BENE_CONTACT_CALLING_CODE: "Bene  联系电话代码码",
		LBL_BENE_CONTACT_NO: "收款人联系电话",
		LBL_BENE_CONTACT_PIC: "收款联系负责人",
		LBL_BENE_ADDRESS: "收款人地址",
		LBL_SAVE_AS_NEW_BANK: "保存新收款人",

		LBL_BANK_NAME: "银行名",
		LBL_BANK_COUNTRY: "银行所在国家",

		LBL_BANK_PROVINCE: "银行所在省",
		LBL_BANK_DISTRICT: "银行所在区",

		LBL_BANK_ADDRESS: "银行地址",
		LBL_BANK_RI_NO: "Ri No.",
		LBL_BANK_SWIFT_CODE: "Swift Code",
		LBL_BANK_IBAN: "Iban Code",

		LBL_INTER_BANK_NAME: "Intermediate Bank Name",
		LBL_INTER_BANK_ACCOUNT_NO: "Intermediate Acc No.",
		LBL_INTER_SWIFT_CODE: "Intermediate Swift Code",
		LBL_INTER_IBAN_CODE: "Intermediate IBAN Code",

		LBL_INTER_ENABLE: "Intermediate Bank ?",
		BANK_MODAL_TITLE: "银行列表",
	}
};
