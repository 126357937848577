import i18nCore from "@shared/src/core/plugins/vue-i18n";
import i18n from "@/core/plugins/vue-i18n";
import jsBarcode from "jsbarcode";
import Swal from "sweetalert2";
import CryptoJS from 'crypto-js';

const ErrorCodeSelection = [
	{
		code: null,
		name: "All",
	},
	{
		code: "403",
		name: "FORBIDDEN (403)",
	},
	{
		code: "400",
		name: "BAD REQUEST (400)",
	},
	{
		code: "2001",
		name: "Bad Credentials (2001)",
	},
	{
		code: "2050",
		name: "Validation Exception (2050)",
	},
	{
		code: "-1",
		name: "Failed (-1)",
	},
	{
		code: "500",
		name: "Internal Server/Exception (500)",
	},
	{
		code: "200",
		name: "Success (200)",
	},
];

const weekdaysSelection = function (locale) {
	let Weekdays = i18nCore.messages[locale].WEEKDAYS;
	return [
		{ value: 1, text: Weekdays.MONDAY },
		{ value: 2, text: Weekdays.TUESDAY },
		{ value: 3, text: Weekdays.WEDNESDAY },
		{ value: 4, text: Weekdays.THURSDAY },
		{ value: 5, text: Weekdays.FRIDAY },
		{ value: 6, text: Weekdays.SATURDAY },
		{ value: 0, text: Weekdays.SUNDAY },
	];
};

const hourRangeSelection = [
	{ value: 8, text: "08:00" },
	{ value: 9, text: "09:00" },
	{ value: 10, text: "10:00" },
	{ value: 11, text: "11:00" },
	{ value: 12, text: "12:00" },
	{ value: 13, text: "13:00" },
	{ value: 14, text: "14:00" },
	{ value: 15, text: "15:00" },
	{ value: 16, text: "16:00" },
	{ value: 17, text: "17:00" },
	{ value: 18, text: "18:00" },
];

const getDayDesc = function (locale, day) {
	let Weekdays = i18nCore.messages[locale].WEEKDAYS;
	let day_desc;
	if (day == "1") {
		day_desc = Weekdays.MONDAY;
	} else if (day == "2") {
		day_desc = Weekdays.TUESDAY;
	} else if (day == "3") {
		day_desc = Weekdays.WEDNESDAY;
	} else if (day == "4") {
		day_desc = Weekdays.THURSDAY;
	} else if (day == "5") {
		day_desc = Weekdays.FRIDAY;
	} else if (day == "6") {
		day_desc = Weekdays.SATURDAY;
	} else {
		day_desc = Weekdays.SUNDAY;
	}
	return day_desc;
};

const getHourDesc = function (hour) {
	if (hour == "8") {
		return "08:00";
	} else if (hour == "9") {
		return "09:00";
	} else if (hour == "10") {
		return "10:00";
	} else if (hour == "11") {
		return "11:00";
	} else if (hour == "12") {
		return "12:00";
	} else if (hour == "13") {
		return "13:00";
	} else if (hour == "14") {
		return "14:00";
	} else if (hour == "15") {
		return "15:00";
	} else if (hour == "16") {
		return "16:00";
	} else if (hour == "17") {
		return "17:00";
	} else {
		return "18:00";
	}
};

const generateBarcode = function (id, barcodeStr, barcode_settings = {
	fontSize: 30,
	height: 30,
	width: 2,
	displayValue: true
}, classname = null) {

	if (emptyObjectValidation(barcode_settings)) {
		barcode_settings = {
			fontSize: 30,
			height: 30,
			width: 2,
			displayValue: true
		}
	}

	if (!emptyObjectValidation(barcodeStr)) {
		jsBarcode(!emptyObjectValidation(classname) ? `.${classname}` : `#${id}`, barcodeStr, barcode_settings);
	}
};



const numberHandle = function (value) {
	if (value == null || value.toString().trim() == "") {
		return parseFloat(0);
	}

	if (value != null && /,/.test(value)) {
		value = value.replaceAll(",", "");
	}

	return parseFloat(value);
};

const selectOption = function (data, custom_option) {
	let default_option = {
		with_select_option: false, //change to [false] will not return select option
		id_field_name: "id", //change id field name
		id_field_value: null, //change id field value
		text_field_name: "name", //change text field name
		text_field_value: i18n.t("COMMON.SELECTION_OPTION_TITLE"), //change text field value
		with_others_option: false, //change to [true] will return others option at last index
		others_id_field_value: 0, //change others option id field value
		others_text_field_value: i18n.t("COMMON.SELECTION_OTHERS"), //change others option text field value,
		other_option_upper: false,
		text_field_value_all: false,
		text_field_none: false
	};
	let option = Object.assign({}, default_option, custom_option);

	if (option.text_field_value_all == true) {
		option.with_select_option = true;
		option.text_field_value = i18n.t("COMMON.TXT_ALL");
	}

	if (option.text_field_none == true) {
		option.with_select_option = true;
		option.text_field_value = i18n.t("COMMON.SELECTION_NONE");
	}

	let result = [];
	if (option.with_select_option === true) {
		let select_option_data = {};

		select_option_data[option.id_field_name] = option.id_field_value;
		select_option_data[option.text_field_name] = option.text_field_value;

		result = result.concat(select_option_data);
	}

	if (typeof data !== "undefined") {
		if (data) {
			if (data.length > 0) {
				result = result.concat(data);
			}
		}
	}

	if (option.with_others_option === true) {
		let others_option_data = {};

		others_option_data[option.id_field_name] = option.others_id_field_value;
		others_option_data[option.text_field_name] = option.other_option_upper ? option.others_text_field_value.toUpperCase() : option.others_text_field_value;

		result = result.concat(others_option_data);
	}

	return result;
};

const onFormatterAccountNo = function (value) {
	if (emptyStringValidation(value)) return value;
	value = value.replace(/\D/g, "");
	value = value.match(/.{1,4}/g);
	return value.join(" ");
};

const onFormatterNo = function (number, decimal_place = 2) {
	if (number == null || number.toString().trim() == "") number = 0;
	if (number == null || isNaN(parseFloat(number))) {
		number = 0;
	}

	if (/,/.test(number)) {
		number = number.replaceAll(",", "");
	}

	number = parseFloat(number).toFixed(decimal_place);
	return new Intl.NumberFormat("en-US", { minimumFractionDigits: decimal_place }).format(number);
}

const resetObjServer = function (obj) {
	return JSON.parse(JSON.stringify(obj));
};

const contactNoFormatHandle = function (calling_code, contact_no) {
	return contact_no != null && contact_no.trim() != `` ? `${calling_code}${contact_no}` : ``
}

const UnixTimeToDateTime = function (unixtimestamp) {
	if (unixtimestamp == null) {
		return "";
	}
	//Convert timestamp to milliseconds
	var date = new Date(unixtimestamp);
	return date.toLocaleString('fr-FR', { hour12: true });

};

const CurDate = function () {
	//Convert timestamp to milliseconds
	var date = new Date();
	// Year
	var year = date.getFullYear();
	// Month
	var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

	// Day
	var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

	// Display date time in MM-dd-yyyy h:m:s format
	var finalDate = year + "-" + month + "-" + day;

	return finalDate;
};

const CurTime = function () {
	//Convert timestamp to milliseconds
	var date = new Date();
	return date.toLocaleTimeString('en-US', { hour12: false, hourCycle: "h24" });

}

const CurDateStartTime = function () {
	var date = new Date(Date.now());
	date.setHours(0, 0, 0, 0)
	let year = date.toLocaleString('en-US', { year: "numeric" });
	let month = date.toLocaleString('en-US', { month: "2-digit" });
	let day = date.toLocaleString('en-US', { day: "2-digit" });
	let time = date.toLocaleTimeString('en-US', { hour12: false, hourCycle: "h24" });
	return `${year}-${month}-${day} ${time}`;
};

const CurDateEndTime = function () {
	var date = new Date(Date.now());
	date.setHours(23, 59, 59, 59)
	let year = date.toLocaleString('en-US', { year: "numeric" });
	let month = date.toLocaleString('en-US', { month: "2-digit" });
	let day = date.toLocaleString('en-US', { day: "2-digit" });
	let time = date.toLocaleTimeString('en-US', { hour12: false, hourCycle: "h24" });
	return `${year}-${month}-${day} ${time}`;
};

const UnixTimeToDate = function (unixtimestamp) {
	if (unixtimestamp == null) {
		return "";
	}
	//Convert timestamp to milliseconds
	var date = new Date(unixtimestamp);
	return date.toLocaleDateString('fr-FR');

};

const soNoFullFormat = function (soNo, versionNo) {
	return `${soNo} - ${versionNo}`;
}

const dateMonthCalc = function (date, no_month_day, add = 0) {
	date.setMonth(add == 1 ? (date.getMonth() + no_month_day) : (date.getMonth() - no_month_day));
	return date.toISOString().substring(0, 10)
}

const dateDayCalc = function (date, no_day, add = 0) {
	date.setDate(add == 1 ? (date.getDate() + no_day) : (date.getDate() - no_day));
	return date.toISOString().substring(0, 10)
}

const handleStatusDesc = function (status, module = null) {
	if (!emptyStringValidation(module) && module == "do-status") {
		return i18n.t(status == 1 ? "COMMON.STATUS.CLOSED" : "COMMON.STATUS.OPEN");
	}
	else if (status == 1) {
		return i18n.t("COMMON.STATUS.ACTIVE");
	} else if (status == 3 && !emptyStringValidation(module) && module == "specialProd") {
		return i18n.t("COMMON.STATUS.UNVERIFIED");
	} else if (![1, 0].includes(status) && !emptyStringValidation(module) && module == "request-account") {
		return i18n.t("COMMON.STATUS.SUSPEND");
	} else {
		return module == "epo" ? i18n.t("EPO_MANAGEMENT.EPO_LISTING.TEXT_SAVE_AS_DRAFT") : i18n.t("COMMON.STATUS.INACTIVE");
	}
}

const handleBadgeVariant = function (status, module = null) {
	if (!emptyStringValidation(status)) {
		if (![1, 0].includes(status) && !emptyStringValidation(module) && module == "request-account") {
			return "warning";
		}
		else if (!emptyStringValidation(module) && module == "loading-status") {
			return status == 1 ? "danger" : "success";
		}
		else if (!emptyStringValidation(module) && module == "do-status") {
			return status == 1 ? "success" : "danger"
		}
		else {
			return status == 1 ? "success" : "danger";
		}
	} else {
		return "default";
	}
}

const handleYesNo = function (value) {
	return value != null && value == 1 ? i18n.t("COMMON.STATUS.YES") : i18n.t("COMMON.STATUS.NO");
}

const emptyStringValidation = function (value, include_zero = 0) {
	return (value == null || value == "NaN" || value.toString().trim() == "") || (include_zero == 1 && parseFloat(value.toString()) == 0) ? true : false
}


const emptyObjectValidation = function (value) {
	if (typeof value == "undefined" || value == null) {
		return true;
	}

	if (typeof value == "object") {
		return value == null || value.length == 0 || Object.entries(value).length == 0 ? true : false;
	} else {
		return emptyStringValidation(value);
	}
};

const summaryPageRowActionValidation = function (data) {
	return !emptyObjectValidation(data) ? data[0] : {};
};

const onlyNumber = function (event) {
	let keyCode = event.keyCode ? event.keyCode : event.which;
	if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
		// 46 is dot
		event.preventDefault();
	}
};

const onlyInteger = function (event) {
	let keyCode = event.keyCode ? event.keyCode : event.which;
	if (keyCode < 48 || keyCode > 57) {
		// 46 is dot
		event.preventDefault();
	}
};


const disabledButton = function (ele) {
	var buttons = document.querySelectorAll(ele)
	buttons.forEach((btn) => {
		btn.classList.add("spinner", "spinner-light", "spinner-right");
		btn.disabled = true;
	})
}

const enableButton = function (ele) {
	var buttons = document.querySelectorAll(ele)
	buttons.forEach((btn) => {
		btn.classList.remove("spinner", "spinner-light", "spinner-right");
		btn.disabled = false;
	});
}

const printToA4Size = {
	portrait: {
		h: 1075,
		w: 790
	},
	landscape: {
		w: 1122,
		h: 729
	},
	portrait_mm: {
		h: 297,
		w: 210
	},
	letter_half_mm: {
		h: 140,
		w: 216
	}
}

const unserializeJsonStr = function (value) {
	if (emptyStringValidation(value)) {
		return value;
	}
	return JSON.parse(value);
};

const serializeJsonString = function (value) {
	if (emptyObjectValidation(value)) {
		value = {};
	}
	return JSON.stringify(value);
};

const handleEnableButtonSpinner = function (element) {
	var buttons = document.querySelectorAll(element);
	buttons.forEach((btn) => {
		btn.classList.add("spinner", "spinner-light", "spinner-right");
		btn.disabled = true;
	});
}

const handleDisableButtonSpinner = function (element) {
	var buttons = document.querySelectorAll(element);
	buttons.forEach((btn) => {
		btn.classList.remove("spinner", "spinner-light", "spinner-right");
		btn.disabled = false;
	});
}


const nltobr = function (val) {
	if (val == null || val.toString().trim() == "") {
		return "";
	}

	return val.replaceAll(/\n/gi, "<br />");
};

const joinStr = function (data, separator = ", ") {
	return data.join(separator);
};


const formatNull = function (value, emptyString = '-') {
	return emptyStringValidation(value) ? emptyString : value;
};

const convertNestedObjectToNull = function (object) {
	//NOTE: MAXIMUM 3 LEVEL ONLY
	let objectFormatted = object;
	if (objectFormatted !== undefined && objectFormatted != null) {
		if (typeof objectFormatted === "object" && objectFormatted !== null && Object.keys(objectFormatted).length > 0) {
			Object.keys(objectFormatted).forEach(function (key) {
				if (objectFormatted[key] !== undefined && objectFormatted[key] != null) {
					if (typeof objectFormatted[key] === "object" && objectFormatted[key] !== null && Object.keys(objectFormatted[key]).length > 0) {
						//second level convert
						Object.keys(objectFormatted[key]).forEach(function (key2) {
							if (objectFormatted[key][key2] !== undefined && objectFormatted[key][key2] != null) {
								if (typeof objectFormatted[key][key2] === "object" && objectFormatted[key][key2] !== null && Object.keys(objectFormatted[key][key2]).length > 0) {
									//third level convert
									Object.keys(objectFormatted[key][key2]).forEach(function (key3) {
										objectFormatted[key][key2][key3] = null;
									});
								} else {
									objectFormatted[key][key2] = null;
								}
							}
						});
					} else {
						objectFormatted[key] = null;
					}
				}
			});
		} else {
			objectFormatted = null;
		}
	}

	return objectFormatted;
}

const textColor = function (hex) {
	if (hex == null) return "#000";
	let c = hex.substring(1).split("");
	if (c.length == 3) {
		c = [c[0], c[0], c[1], c[1], c[2], c[2]];
	}
	c = "0x" + c.join("");
	let rgb = "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)";
	let a = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/),
		r = a[1],
		g = a[2],
		b = a[3];
	var hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
	return hsp > 170 ? "#000" : "#fff";
};

const colorIndicatorStyle = function (bgColor, textColor, boxType = 0) {
	if (bgColor == null) bgColor = "#fff";
	return {
		backgroundColor: bgColor,
		border: bgColor == "#FFFFFF" ? "solid 1px #c7c5c5" : "none",
		cursor: "pointer",
		height: boxType == 2 ? "20px" : "26px",
		"line-height": boxType == 2 ? "20px" : "26px",
		padding: "0 10px",
		color: textColor,
		"text-align": boxType == 0 ? "left" : "center",
		transition: "border-radius 200ms ease-in-out",
		"font-weight": "900",
		"margin-bottom": boxType == 2 ? "3px" : "0"
	};
};

const onFormatterUpperCase = function (v, limit_length = 0) {
	if (v == null) return v;
	if (limit_length != 0) return v.slice(0, limit_length).toUpperCase();
	return v.toUpperCase();
};

const footerProps = {
	"items-per-page-options": [10, 20, 50, 100, 500, 1000],
	showCurrentPage: true
};

const datatableOptions = {
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	sortDesc: [],
	groupBy: [],
	groupDesc: [],
	multiSort: true,
	mustSort: true
};

const roundNumber = function (number, decimal_place = 2) {
	if (number == null) number = 0;
	number = numberHandle(number);
	const multiplier = Math.pow(10, decimal_place);
	// return parseFloat(number).toFixed(decimal_place);
	return Math.round(number * multiplier) / multiplier;
}

const formDate = function (timestamp) {
	if (emptyObjectValidation(timestamp)) {
		return "";
	}

	var date = new Date(timestamp);

	var year = date.toLocaleString("default", { year: "numeric" });
	var month = date.toLocaleString("default", { month: "2-digit" });
	var day = date.toLocaleString("default", { day: "2-digit" });

	// Generate yyyy-mm-dd date string
	// var formattedDate = year + "-" + month + "-" + day;

	// var date;
	// if (isTimeNumber) {
	// 	// var offset = new Date(0).getTimezoneOffset();
	// 	// date = new Date(0, 0, timestamp, 0, -offset, 0);
	// 	// console.log(date);

	// 	var utc_days = Math.floor(timestamp - 25569);
	// 	var utc_value = utc_days * 86400;
	// 	date = new Date(utc_value * 1000);

	// } else {
	// 	date = new Date(timestamp);
	// }
	// // Year
	// var year = date.getFullYear();

	// // Month
	// var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

	// // Day
	// var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

	// // Display date time in MM-dd-yyyy h:m:s format
	var finalDate = year + "-" + month + "-" + day;

	return finalDate;
};

const editPage = function (path) {
	return path.indexOf("edit") !== -1 ? true : false;
};

const numberValidation = function (number) {
	if (/,/.test(number)) {
		number = number.replaceAll(",", "");
	}

	return isNaN(parseFloat(number)) ? false : true;
}

const onFormatterLowerCase = function (v, limit_length = 0) {
	if (v == null) return v;
	if (limit_length != 0) return v.slice(0, limit_length).toLowerCase();
	return v.toLowerCase();
};

const handleArrayLastIndex = function (data) {
	return emptyObjectValidation(data) ? 0 : (parseInt(data.length) - 1);
}

const countObjLen = function (data) {
	return emptyObjectValidation(data) ? 0 : parseInt(data.length);
}

const numberConvertExcelCol = function (number) {
	let result = '';
	do {
		const letter = String.fromCharCode(65 + (number % 26));
		result = letter + result;
		number = Math.floor(number / 26) - 1;
	} while (number >= 0)
	return result;
}

const saveFormSession = function (form, session_name) {
	sessionStorage.setItem(session_name, serializeJsonString(form))
}

const initFormSesssion = function (session_name, form) {
	const formData = unserializeJsonStr(sessionStorage.getItem(session_name));
	return emptyObjectValidation(formData) ? form : formData;
}

const handleTrimString = function (v) {
	return emptyObjectValidation(v) ? "" : v.toString().trim();
}

const pixelToMm = function (pixel) {
	return Math.ceil(pixel * 0.2645833333);
}

const handleConfirmationBox = async function (msg = "", title = i18n.t("COMMON.MSG.ARE_YOU_SURE"), icon = "info", cancel_btn = i18n.t("COMMON.BTN_NO"), confirm_btn = i18n.t("COMMON.BTN_YES")) {
	let response = await Swal.fire({
		title: title,
		html: msg,
		icon: icon,
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		cancelButtonColor: "#d33",
		cancelButtonText: cancel_btn,
		confirmButtonText: confirm_btn
	});
	return response;
};

const handleDialogBoxWidth = function () {
	let width = emptyObjectValidation(window.innerWidth) ? 600 : Math.floor(parseInt(window.innerWidth) * 0.5);
	return parseInt(width) == 0 ? `600px` : `${width}px`;
}

const encrypData = function (data) {
	const secret = "E2OZyKHKBqhvY9FqLfaouSIA7zQCDLSM";
	const iv = "0000000000000000";

	var encrpyted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secret), {
		iv: CryptoJS.enc.Utf8.parse(iv),
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	})

	return encrpyted.toString();
}

const encryJsonPostData = function (post_data) {
	return {
		form: btoa(encrypData(JSON.stringify(post_data)))
	}
}

const dialogboxWidth = function (rate = 0.5) {
	let width = emptyObjectValidation(window.innerWidth) ? 600 : Math.floor(parseInt(window.innerWidth) * rate);
	return parseInt(width) == 0 ? `600px` : `${width}px`;
}

const dialogboxHeight = function () {
	let height = emptyObjectValidation(window.innerHeight) ? 600 : Math.floor(parseInt(window.innerHeight) * 0.5);
	return parseInt(height) == 0 ? `600px` : `${height}px`;
}

const mathAdd = function (x, y, decimal_place = 2) {
	return roundNumber(numberHandle(x) + numberHandle(y), decimal_place);
}

const mathDivide = function (x, y, decimal_place = 2) {
	if (numberHandle(x) == 0 || numberHandle(y) == 0) return 0;
	return roundNumber(numberHandle(x) / numberHandle(y), decimal_place);
}

const mathMultiply = function (x, y, decimal_place = 2) {
	return roundNumber(numberHandle(x) * numberHandle(y), decimal_place);
}

const mathSubtract = function (x, y, decimal_place = 2) {
	return roundNumber(numberHandle(x) - numberHandle(y), decimal_place);
}

export default {
	colorIndicatorStyle,
	contactNoFormatHandle,
	convertNestedObjectToNull,
	CurDate,
	CurTime,
	CurDateEndTime,
	CurDateStartTime,
	datatableOptions,
	dateDayCalc,
	dateMonthCalc,
	disabledButton,
	emptyObjectValidation,
	emptyStringValidation,
	enableButton,
	ErrorCodeSelection,
	footerProps,
	formatNull,
	generateBarcode,
	getDayDesc,
	getHourDesc,
	handleBadgeVariant,
	handleDisableButtonSpinner,
	handleEnableButtonSpinner,
	handleStatusDesc,
	handleYesNo,
	hourRangeSelection,
	joinStr,
	nltobr,
	numberHandle,
	numberValidation,
	onFormatterAccountNo,
	onFormatterNo,
	onFormatterUpperCase,
	onlyInteger,
	onlyNumber,
	printToA4Size,
	resetObjServer,
	roundNumber,
	selectOption,
	soNoFullFormat,
	summaryPageRowActionValidation,
	textColor,
	UnixTimeToDate,
	UnixTimeToDateTime,
	unserializeJsonStr,
	weekdaysSelection,
	formDate,
	editPage,
	onFormatterLowerCase,
	handleArrayLastIndex,
	countObjLen,
	numberConvertExcelCol,
	serializeJsonString,
	saveFormSession,
	initFormSesssion,
	handleTrimString,
	pixelToMm,
	handleConfirmationBox,
	handleDialogBoxWidth,
	encrypData,
	encryJsonPostData,
	dialogboxWidth,
	dialogboxHeight,
	mathAdd,
	mathDivide,
	mathMultiply,
	mathSubtract
};
