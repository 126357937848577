<template>
	<div>
		<slot name="otherBtnSlot"></slot>
		<b-button variant="info" size="sm" v-show="print_button == 1" @click="$emit('print')" class="mr-1">
			<b-icon-printer-fill></b-icon-printer-fill>
			<span class="ml-1" v-text="$t('COMMON.BTN_PRINT')"></span>
		</b-button>
		<b-button
			variant="info"
			size="sm"
			v-if="is_view == 0 && hasSaveAsDraftListener && permission_save_as_draft == true"
			:disabled="state == 0"
			class="mr-1"
			@click="$emit('hasSaveAsDraftListener')"
		>
			<span v-text="$t('COMMON.BTN_SAVE')"></span>
		</b-button>
		<b-button :to="createRoute" variant="primary" size="sm" v-show="is_view == 1 && detail_page == 1 && createRoute && permission_create == true" :disabled="state == 0" class="mr-1">
			<span v-text="$t('COMMON.BTN_CREATE', { btn_name: title })"></span>
		</b-button>

		<!-- <b-button variant="success" size="sm" v-show="copy_button == 1 && is_view == 1" :to="copyRoute" class="mr-1">
			<i class="fas fa-copy"></i>
			<span class="ml-1" v-text="$t('COMMON.BTN_COPY')"></span>
		</b-button> -->

		<b-button
			:type="saveButtonType"
			variant="success"
			size="sm"
			v-if="submit_btn == 0 && is_view == 0 && permission_save == true"
			:disabled="state == 0"
			v-on="saveButtonType != 'submit' ? { click: () => $emit('saveButtonMethod') } : {}"
		>
			<span v-text="submit_title"></span>
		</b-button>

		<b-button type="submit" variant="success" size="sm" v-show="submit_btn == 1 && is_view == 0 && permission_save == true" :disabled="state == 0">
			<span v-text="$t('COMMON.BTN_SUBMIT')"></span>
		</b-button>
		<b-button :to="editRoute" variant="success" size="sm" v-show="is_view == 1 && editRoute && permission_edit == true">
			<b-icon-pencil-square></b-icon-pencil-square>
			<span class="ml-1" v-text="$t('COMMON.BTN_EDIT')"></span>
		</b-button>
		<b-button variant="danger" :to="indexRoute" class="ml-1" size="sm" v-show="indexRoute && (permission_list == null || permission_list == true)">
			<span v-text="$t('COMMON.BTN_BACK')"></span>
		</b-button>
	</div>
</template>

<script>
export default {
	name: "FormButton",
	props: {
		indexRoute: String,
		editRoute: String,
		createRoute: String,
		is_view: null,
		modules: null,
		state: null,
		permission_save_as_draft: null,
		permission_save: null,
		permission_list: null,
		permission_edit: null,
		permission_create: null,
		detail_page: null,
		title: null,
		copyRoute: String,
		submit_btn: {
			type: Number,
			default() {
				return 0;
			}
		},
		print_button: {
			type: Number,
			default: 0
		},
		copy_button: {
			type: Number,
			default: 0
		},
		submit_title: {
			type: String,
			default() {
				return this.$t("COMMON.BTN_SAVE");
			}
		}
	},
	computed: {
		hasSaveAsDraftListener() {
			return this.$listeners && this.$listeners.hasSaveAsDraftListener;
		},
		saveButtonType() {
			if (this.$listeners && this.$listeners.saveButtonMethod) {
				return "button";
			}

			return "submit";
		}
	}
};
</script>
