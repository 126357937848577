<template>
	<v-autocomplete
		v-model="mValue"
		:items="selection"
		:item-value="value_key"
		:item-text="text_key"
		hide-details
		dense
		:outlined="outline"
		:placeholder="handlePlaceholder"
		:label="handleLabel"
		:clearable="clearable"
		@keydown.prevent.enter
		@focus="$emit('focus')"
		:class="classname"
		@change="$emit('change')"
		@mouseenter="$emit('mouseenter')"
		:disabled="is_view == 1"
		:chips="multiple"
		:small-chips="multiple"
		:multiple="multiple"
		:deletable-chips="multiple"
		:loading="loading"
		:search-input.sync="searchInput"
		:hide-no-data="enable_search"
	>
		<template v-slot:item="data" v-if="custom_selection == 1">
			<v-list-item
				:disabled="
					wms == 0 &&
					['marking', 'delivery_address'].includes(field_type) &&
					typeof data.item[status_key] != 'undefined' &&
					status(data.item) == 3
						? true
						: false
				"
				v-on="data.on"
				v-bind="data.attrs"
				v-show="show(data.item)"
				#default="{ active }"
			>
				<v-list-item-action v-if="multiple">
					<v-checkbox :input-value="active"></v-checkbox>
				</v-list-item-action>
				<vListContent
					:item="data.item"
					:field_type="field_type"
					:text_key="text_key"
					:status_key="status_key"
					:tooltip_required="tooltip_required"
					:customer="customer"
					:tooltip_key="tooltip_key"
					:value_key="value_key"
				></vListContent>
			</v-list-item>
		</template>
	</v-autocomplete>
</template>

<style>
.v-autocomplete.v-text-field--outlined legend {
	width: 0 !important;
}
</style>

<script>
import vListContent from "@shared/src/components/vListContent.vue";
export default {
	computed: {
		mValue: {
			get() {
				return this.data;
			},
			set(newVal) {
				this.$emit("update", newVal);
			},
		},
		handlePlaceholder() {
			return !this.hideLabel
				? this.show_all_label
					? this.$t("COMMON.TXT_ALL")
					: this.placeholder
				: "";
		},
		handleLabel() {
			return this.multiple && !this.hideLabel
				? this.show_all_label
					? this.$t("COMMON.TXT_ALL")
					: this.label
				: "";
		},
	},
	components: {
		vListContent,
	},
	props: {
		data: null,
		clearable: {
			type: Boolean,
			default() {
				return false;
			},
		},
		selection: null,
		value_key: {
			type: String,
			default() {
				return "id";
			},
		},
		text_key: {
			type: String,
			default() {
				return "name";
			},
		},
		status_key: {
			type: String,
			default() {
				return "status";
			},
		},
		classname: null,
		custom_selection: {
			type: Number,
			default() {
				return 0;
			},
		},
		field_type: {
			type: String,
			default() {
				return null;
			},
		},
		is_view: {
			type: Number,
			default() {
				return 0;
			},
		},
		multiple: {
			type: Boolean,
			default() {
				return false;
			},
		},
		tooltip_required: {
			type: Number,
			default() {
				return 0;
			},
		},
		tooltip_key: {
			type: String,
			default() {
				return null;
			},
		},
		customer: {
			type: Number,
			default() {
				return 0;
			},
		},
		wms: {
			type: Number,
			default() {
				return 0;
			},
		},
		loading: {
			type: Boolean,
			default() {
				return false;
			},
		},
		enable_search: {
			type: Boolean,
			default() {
				return false;
			},
		},
		placeholder: {
			type: String,
			default() {
				return this.$t("COMMON.SELECTION_OPTION_TITLE");
			},
		},
		label: {
			type: String,
			default() {
				return this.$t("COMMON.SELECTION_OPTION_TITLE");
			},
		},
		hideLabel: {
			type: Boolean,
			default() {
				return false;
			},
		},
		filter_val: {
			type: Object,
			default() {
				return {};
			},
		},
		outline: {
			type: Boolean,
			default() {
				return true;
			},
		},
		show_all_label: {
			type: Boolean,
			default() {
				return false;
			},
		},
		ignore_value: null,
		inc_inactive:{
			type: Boolean,
			default() {
				return false;
			},
		}
	},
	watch: {
		searchInput(val) {
			if (this.enable_search) {
				clearTimeout(this.timer);

				this.timer = setTimeout(() => {
					this.$emit("search", val);
				}, 200);
				
			}
		},
	},
	data() {
		return {
			searchInput: null,
		};
	},
	methods: {
		show(item) {
			if (this.field_type == "wms_ref_id") {
				return (!this.$CoreSettingsJs.emptyStringValidation(this.data) &&
					this.data == item[this.value_key]) ||
					(item.loaded == this.filter_val.loaded &&
						item.loading_status == this.filter_val.loading_status &&
						item.set_linkage == this.filter_val.set_linkage)
					? true
					: false;
			} else if (
				!this.$CoreSettingsJs.emptyObjectValidation(this.ignore_value) &&
				this.ignore_value.includes(item[this.value_key])
			) {
				return false;
			} else {
				return this.$CoreSettingsJs.emptyStringValidation(item[this.status_key]) ||
				(this.inc_inactive  && parseInt(item[this.status_key]) == 0) ||
					(parseInt(item[this.status_key]) != 0 && parseInt(item[this.status_key]) != 2) ||					
					(!this.$CoreSettingsJs.emptyStringValidation(this.data) && this.data == item[this.value_key])
					? true
					: false;
			}
		},
		name(item) {
			return item[this.text_key];
		},
		status(item) {
			return item[this.status_key];
		},
		tooltip(item) {
			return item[this.tooltip_key];
		},
	},
};
</script>
