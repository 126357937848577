<template>
	<b-card no-body :class="!$CoreSettingsJs.emptyStringValidation(cardClass) ? cardClass : null">
		<b-card-header id="gsumary_card_header" ref="cardHeader" class="p-2" v-if="!hideCardHeader">
			<b-row class="mt-2" v-show="!$CoreSettingsJs.emptyStringValidation(summaryTitle)">
				<b-col class="d-flex align-self-end">
					<h4 class="" v-text="summaryTitle"></h4>
					<a class="ml-2" :aria-controls="controlsName" @click="collapseVisible = !collapseVisible" v-if="!hideSearchForm">
						<span class="when-open">
							<i class="fa fa-chevron-down" aria-hidden="true" v-if="!collapseVisible"></i>
						</span>
						<span class="when-closed">
							<i class="fa fa-chevron-up" aria-hidden="true" v-if="collapseVisible"></i>
						</span>
					</a>
				</b-col>
				<b-col align="right" class="d-flex flex-nowrap justify-content-end summary_page">
					<div class="mr-1" v-if="sync_wms == 1">
						<b-button @click="$emit('syncAll')" variant="primary" size="sm" ref="sync_all" class="sync_btn">
							<i class="fas fa-sync"></i>
							<span v-text="syncTitle"></span>
						</b-button>
						<v-overlay :value="overlay">
							<v-progress-circular indeterminate size="64"></v-progress-circular>
						</v-overlay>
					</div>
					<div class="mr-1" v-if="sync_agent == 1">
						<b-button @click="$emit('syncAllAgent')" variant="primary" size="sm" ref="sync_all" class="sync_btn">
							<i class="fas fa-sync"></i>
							<span v-text="syncAgentTitle"></span>
						</b-button>
						<v-overlay :value="agent_overlay">
							<v-progress-circular indeterminate size="64"></v-progress-circular>
						</v-overlay>
					</div>
					<div class="mr-1" v-if="createRoute != null">
						<slot name="otherHeaderButton"></slot>
						<b-button v-if="!hideCreateButton" :href="createRoute" variant="success" class="float-right" size="sm" :disabled="!hasCreatePermission">
							<b-icon-plus-square-fill v-show="buttonName == null"></b-icon-plus-square-fill>
							<span :class="buttonName == null ? `ml-1` : ``" v-text="buttonName != null ? buttonName : $t('COMMON.BTN_CREATE', { btn_name: moduleName })"></span>
						</b-button>
						<slot name="cancelButton"></slot>
					</div>
					<div align="right" v-else>
						<slot name="headerButton"></slot>
					</div>
					<div align="right">
						<slot name="cancelBtn"></slot>
					</div>
				</b-col>
			</b-row>
			<b-collapse v-show="!hideSearchForm" :id="controlsName" v-model="collapseVisible" class="mt-3" :disabled="!hideSearchForm" @hidden="hiddenSize" @shown="showSize">
				<slot ref="collapse_form" name="searchFormSlot"></slot>
			</b-collapse>
		</b-card-header>
		<b-card-body>
			<slot class="buttonSlot" name="summaryButtonSlot"></slot>
			<slot name="dataSlot"></slot>
		</b-card-body>
	</b-card>
</template>

<script>
export default {
	name: "GimSummaryPage",
	props: {
		summaryTitle: String,
		createRoute: null,
		hasCreatePermission: false,
		moduleName: null,
		controlsName: null,
		hideSearchForm: false,
		buttonName: null,
		hideCreateButton: false,
		sync_wms: {
			type: Number,
			default: 0
		},
		sync_agent: {
			type: Number,
			default: 0
		},
		syncTitle: {
			type: String,
			default() {
				return this.$t("COMMON.BTN_SYNC_ALL_WAREHOUSE");
			}
		},
		syncAgentTitle: {
			type: String,
			default() {
				return this.$t("COMMON.BTN_SYNC_ALL_AGENT");
			}
		},
		overlay: false,
		cardClass: {
			type: String,
			default() {
				return null;
			}
		},
		hideCardHeader: false
	},
	mounted() {},
	data() {
		return {
			collapseVisible: true,
			agent_overlay: false
		};
	},
	methods: {
		hiddenSize() {
			const allocateHeight = this.$Common.dataTableHeightHandle();
			if (document.querySelector("div.vdatable > div.v-data-table__wrapper") !== null) document.querySelector("div.vdatable > div.v-data-table__wrapper").style.height = `${allocateHeight}px`;
		},
		showSize() {
			const allocateHeight = this.$Common.dataTableHeightHandle();
			if (document.querySelector("div.vdatable > div.v-data-table__wrapper") !== null) document.querySelector("div.vdatable > div.v-data-table__wrapper").style.height = `${allocateHeight}px`;
		}
	}
};
</script>
