import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import Common from "@/core/services/common";
import CoreJs from "@/core/services/core.js";
import CoreSettingsJs from "@shared/src/core/services/settings.js";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { GET_SYS_SETTINGS, GET_LOCALE } from "@/core/services/store/syssettings.module";
import Antd from "ant-design-vue";
import AsyncComputed from "vue-async-computed";

Vue.config.productionTip = false;

import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;

import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import VModal from "vue-js-modal";
import Swal from "sweetalert2";
import SelectField from "@shared/src/components/SelectionField.vue";
import StringConversion from "@/core/services/directive/string.conversion.js";
import "ant-design-vue/dist/antd.css";
import "@shared/src/assets/css/gbizsUtilities.css";
import JsBarcode from "@shared/src/components/JsBarcode";
import JsonViewer from 'vue-json-viewer';
import DateRangePicker from "@shared/src/components/dateRangePicker.vue";
import GimMasterForm from "@/view/layout/form/GimMasterForm.vue";
import ButtonForm from "@/view/content/form/formButton.vue";
import FormAlert from "@/view/content/FormAlert";
import CreationModified from "@/view/content/form/CreationModified.vue";
import StandardSearchForm from "@/view/content/StandardSearchForm.vue";
import GimFormSection from "@/view/content/GimFormSection";

import GimSummary from "@/view/layout/form/GimSummary.vue";
import GimMasterDataSummary from "@/view/content/GimMasterDataSummary.vue";
import GimSummaryRowAction from "@/view/content/GimSummaryRowAction.vue";
import PhoneField from "@shared/src/components/phoneField.vue";


Vue.config.productionTip = false;
Vue.use(JsBarcode);
Vue.use(VModal);
Vue.use(Antd);
Vue.use(AsyncComputed);
Vue.use(JsonViewer);
Vue.prototype.$enviroment = process.env.NODE_ENV;
Vue.prototype.$ApiService = ApiService;
Vue.prototype.$Common = Common;
Vue.prototype.$CoreJs = CoreJs;
Vue.prototype.$Swal = Swal;
Vue.prototype.$CoreSettingsJs = CoreSettingsJs;


Vue.component("selectField", SelectField);
Vue.directive("strToUppercase", StringConversion.strToUppercase);
Vue.directive("strToUppercase25", StringConversion.strToUppercase25);
Vue.component("dateRangePicker", DateRangePicker);
Vue.component("gimMasterForm", GimMasterForm);
Vue.component("buttonForm", ButtonForm);
Vue.component("formAlert", FormAlert);
Vue.component("creationModified", CreationModified);
Vue.component("standardSearchForm", StandardSearchForm);

Vue.component("gimSummary", GimSummary);
Vue.component("gimMasterDataSummary", GimMasterDataSummary);
Vue.component("gimSummaryRowAction", GimSummaryRowAction);
Vue.component("gimFormSection", GimFormSection);
Vue.component("phoneField", PhoneField);

ApiService.init();

router.beforeEach((to, from, next) => {
	document.title = to.meta.title || "GBIZS System";
	let middleware = [];
	if (store.getters.isAuthenticated) {
		middleware.push(store.dispatch(VERIFY_AUTH), store.dispatch(GET_SYS_SETTINGS), store.dispatch(GET_LOCALE));
		//middleware = [store.dispatch(VERIFY_AUTH), store.dispatch(GET_SYS_SETTINGS), store.dispatch(GET_LOCALE)];
	} else {
		middleware = [store.dispatch(VERIFY_AUTH)];
	}
	// Ensure we checked auth before each page load.
	Promise.all(middleware)
		.then(next)
		.catch(() => router.push({ name: "login" }));

	// reset config to initial state
	store.dispatch(RESET_LAYOUT_CONFIG);

	// Scroll page to top on every route change
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
});

new Vue({
	router,
	store,
	i18n,
	vuetify,
	render: (h) => h(App)
}).$mount("#app");
