import i18n from "@/core/plugins/vue-i18n";
import Swal from "sweetalert2";
import CoreSettingsJs from "@shared/src/core/services/settings.js";

const epo_status_options = [
	{
		item: null,
		name: i18n.t("COMMON.TXT_ALL")
	},
	{
		item: 1,
		name: i18n.t("COMMON.STATUS.ACTIVE")
	},
	{
		item: 0,
		name: i18n.t("COMMON.STATUS.DRAFT")
	},
	{
		item: 2,
		name: i18n.t("COMMON.STATUS.INACTIVE")
	}
];


const formula_options = [
	{
		id: 1,
		name: i18n.t("COMMON.FORMULA_SELECTION.BASE_RATE")
	},
	{
		id: 2,
		name: i18n.t("COMMON.FORMULA_SELECTION.MULTIPLE_RATE")
	}
];

const viewPage = function (path) {
	return path.indexOf("detail") !== -1 ? 1 : 0;
};

const UnixTimeToDateTime = function (unixtimestamp) {
	if (unixtimestamp == null) {
		return "";
	}
	//Convert timestamp to milliseconds
	var date = new Date(unixtimestamp);

	// Year
	var year = date.getFullYear();

	// Month
	var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

	// Day
	var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

	// Hours
	var hours = date.getHours();

	// Minutes
	var minutes = "0" + date.getMinutes();

	// Seconds
	var seconds = "0" + date.getSeconds();

	// Display date time in MM-dd-yyyy h:m:s format
	var datetime = day + "/" + month + "/" + year + " " + hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
	return datetime;
};

const UnixTimeToDate = function (timstamp) {
	if (timstamp == null) {
		return "";
	}

	//Convert timestamp to milliseconds
	var date = new Date(timstamp);

	// Year
	var year = date.getFullYear();

	// Month
	var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

	// Day
	var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

	// Display date time in MM-dd-yyyy h:m:s format
	var finalDate = year + "-" + month + "-" + day;

	return finalDate;
};

const validateEmail = function (email) {
	return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) ? true : false;
};

const UnixTimeToYM = function (timestamp) {
	//Convert timestamp to milliseconds
	var date = new Date(timestamp);

	// Year
	var year = date.getFullYear().toString().substr(-2);

	// Month
	var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

	var finalDate = year + month;

	return finalDate;
};

const UnixTimeToYear = function (timestamp) {
	//Convert timestamp to milliseconds
	var date = new Date(timestamp);

	// Year
	var year = date.getFullYear().toString().substr(-2);
	return year;
};

const resetObServer = function (obj) {
	return obj == null ? null : JSON.parse(JSON.stringify(obj));
};

const numberDecimal = function (number, decimal_place = 2) {
	if (number == null) number = 0;
	return parseFloat(number).toFixed(decimal_place);
};

const numberFormatCurrency = function (number, decimal_place = 2) {
	if (/,/.test(number)) {
		number = number.replaceAll(",", "");
	}
	if (number == null || number.toString().trim() == "") number = 0;
	if (number == null || isNaN(parseFloat(number))) {
		number = 0;
	}

	if (/,/.test(number)) {
		number = number.replaceAll(",", "");
	}

	return new Intl.NumberFormat("en-US", { minimumFractionDigits: decimal_place }).format(parseFloat(number).toFixed(decimal_place));
};

const dimensionFormat = function (number) {
	if (/,/.test(number)) {
		number = number.replaceAll(",", "");
	}

	if (number == null || number.toString().trim() == "") number = 0;
	return new Intl.NumberFormat("en-US", { minimumFractionDigits: 4 }).format(parseFloat(number));
};

const numberFormatInteger = function (number) {
	if (/,/.test(number)) {
		number = number.replaceAll(",", "");
	}
	if (number == null || number.toString().trim() == "" || isNaN(parseFloat(number))) number = 0;
	return new Intl.NumberFormat("en-US").format(parseFloat(number).toFixed(0));
};

const numberFormatM3 = function (number, decimal_place = 4) {
	if (number == null || number.toString().trim() == "" || isNaN(parseFloat(number))) {
		number = 0;
	}

	if (number != null && /,/.test(number)) {
		number = number.replaceAll(",", "");
	}

	return new Intl.NumberFormat("en-Us", { minimumFractionDigits: decimal_place }).format(parseFloat(number));
};

const onFormatterUpperCase = function (v, limit_length = 0) {
	if (v == null) return v;
	if (limit_length != 0) return v.slice(0, limit_length).toUpperCase();
	return v.toUpperCase();
};

const onFormatterLowerCase = function (v, limit_length = 0) {
	if (v == null) return v;
	if (limit_length != 0) return v.slice(0, limit_length).toLowerCase();
	return v.toLowerCase();
};

const NumberFormatToInteger = function (value) {
	if (value == null || value.toString().trim() == "" || isNaN(parseFloat(value))) {
		value = 0;
	}

	if (value != null && /,/.test(value)) {
		value = value.replaceAll(",", "");
	}

	return parseInt(value);
};

const numberHandle = function (value) {
	if (value == null || value.toString().trim() == "") {
		return parseFloat(0);
	}

	if (value != null && /,/.test(value)) {
		value = value.replaceAll(",", "");
	}

	return parseFloat(value);
};

const ucfirst = function (value) {
	if (/\n/.test(value)) {
		return value
			.split("\n")
			.map((val1) =>
				val1
					.split(" ")
					.map((val) => val.charAt(0).toUpperCase() + val.slice(1).toLowerCase())
					.join(" ")
			)
			.join("\n");
	} else {
		return value
			.split(" ")
			.map((val) => val.charAt(0).toUpperCase() + val.slice(1).toLowerCase())
			.join(" ");
	}
};

const setLanguageRecord = function (locales) {
	let records = [];
	for (let idx in locales) {
		records.push({
			locale_id: locales[idx].id,
			locale_name: locales[idx].name,
			locale: locales[idx].lang,
			value: null,
			value_state: null,
			value_error: null
		});
	}

	return records;
};

const getStatusBadge = function (status, module = null) {
	if (!CoreSettingsJs.emptyStringValidation(status)) {
		if (![1, 0].includes(status) && !CoreSettingsJs.emptyStringValidation(module) && module == "request-account") {
			return "warning";
		} else if (status == 1) {
			return "success";
		} else {
			return "danger";
		}
	} else {
		return "default";
	}
};

const getStatusName = function (status, module = null) {
	if (status == 1) {
		return i18n.t("COMMON.STATUS.ACTIVE");
	} else if (status == 3 && !CoreSettingsJs.emptyStringValidation(module) && module == "specialProd") {
		return i18n.t("COMMON.STATUS.UNVERIFIED");
	} else if (![1, 0].includes(status) && !CoreSettingsJs.emptyStringValidation(module) && module == "request-account") {
		return i18n.t("COMMON.STATUS.SUSPEND");
	} else {
		return module == "epo" ? i18n.t("EPO_MANAGEMENT.EPO_LISTING.TEXT_SAVE_AS_DRAFT") : i18n.t("COMMON.STATUS.INACTIVE");
	}
};

const getYesNo = function (value) {
	return value != null && value == 1 ? i18n.t("COMMON.STATUS.YES") : i18n.t("COMMON.STATUS.NO");
};

const expireBadgeHandle = function (value) {
	return value == 0 ? "success" : "danger";
};

const expireValidHandle = function (value) {
	return value == null || value == 0 ? i18n.t("COMMON.STATUS.VALID") : i18n.t("COMMON.STATUS.EXPIRE");
};

const editorSettings = {
	modules: {
		toolbar: {
			container: [
				[{ size: ["small", false, "large"] }],
				["bold", "italic", "underline", "strike"],
				["blockquote", "code-block"],
				[{ header: 1 }, { header: 2 }],
				[{ list: "ordered" }, { list: "bullet" }],
				[{ script: "sub" }, { script: "super" }],
				[{ indent: "-1" }, { indent: "+1" }],
				[{ direction: "rtl" }],
				[{ header: [1, 2, 3, 4, 5, 6, false] }],
				[{ color: [] }, { background: [] }],
				[{ font: [] }],
				[{ align: [] }],
				["clean"],
				["link", "image", "video"]
			]
		}
	}
};

const validateContactNo = function (calling_code, contact_no, return_msg = false, contact_no_label = "") {
	let return_err_msg = return_msg;
	let return_data = {
		status: false,
		message: ""
	};

	let full_contact = `${calling_code}${contact_no}`;
	let regex = /^\+(?:[0-9]?){6,14}[0-9]{10}$/;

	if (calling_code == "+60") {
		regex = /^(\+?6?01)[0-46-9]-*[0-9]{7,10}$|^(\+60)[1-9]{1,2}-*[0-9]{6,10}$/;
	}

	if (regex.test(full_contact)) {
		return_data.status = true;
	} else {
		return_data.message = i18n.t("COMMON.VALIDATION.INVALID", { name: contact_no_label });

		if (calling_code == "+60") {
			return_data.message = i18n.t("COMMON.VALIDATION.INVALID_CONTACT_NO_FORMAT.FORMAT_MY", { name: contact_no_label });
		}
	}
	return return_err_msg === true ? return_data : return_data.status;
};

const filterActionField = function (is_view, fields) {
	return is_view == 1 ? fields.filter((field) => field.key != "action") : fields;
};

const numberChecking = function (value) {
	if (value == null || value.toString().trim() == "") {
		value = 0;
	}

	if (value != null && /,/.test(value)) {
		value = value.replaceAll(",", "");
	}
	return isNaN(parseFloat(value)) ? false : true;
};

const onFormatterPhoneNumber = function (value, calling_code = "+60") {
	if (calling_code == "+86") {
		return value.replace(/\D/g, "");
	} else {
		if (value.charAt(0) == "0") {
			value = value.slice(1);
		}
		return value.replace(/\D/g, "");
	}
};

const onPressPhoneNumber = function (evt) {
	evt = evt ? evt : window.event;
	var charCode = evt.which ? evt.which : evt.keyCode;
	if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		evt.preventDefault();
	} else {
		return true;
	}
};

const IntegerChecking = function (value) {
	if (value == null) {
		return parseInt(0);
	}

	if (value != null && /,/.test(value)) {
		value = value.replaceAll(",", "");
	}
	return isNaN(parseInt(value)) ? false : true;
};

const onFormatterAccountNo = function (value) {
	value = value.replace(/\D/g, "");
	value = value.match(/.{1,4}/g);
	return value.join(" ");
};

const resetOptionsValue = function (data) {
	let options = [];

	for (let idx in data) {
		options.push({ value: data[idx].id, text: data[idx].name });
	}

	return options;
};

const resetSelectionValue = function (selection, current_value, field_name = "id") {
	return CoreSettingsJs.emptyObjectValidation(selection.filter(item => item[field_name] == current_value)) ? true : false;
};

const resetSelectionObject = function (selection, current_value) {
	let resetVal = [];
	for (let idx in current_value) {
		let reset = true;
		for (let cidx in selection) {
			if (selection[cidx].id == current_value[idx]) {
				reset = false;
				break;
			}
		}
		if (reset) {
			resetVal.push(idx);
		}
	}

	if (resetVal.length > 0) {
		for (let idx in resetVal) {
			current_value.splice(resetVal[idx], 1);
		}
	}
	return current_value;
};

const getWordingFromi18n = function (locale, keys) {
	let wording = null;
	if (typeof i18n.messages[locale] != `undefined`) {
		let messages = null;
		for (let i = 0; i < keys.length; i++) {
			if (i18n.messages[locale][keys[i]] == `undefined` || (messages != null && typeof messages[keys[i]] == "undefined")) {
				break;
			} else {
				if (parseInt(i) == parseInt(keys.length - 1)) {
					wording = messages[keys[i]];
				} else {
					if (messages == null) {
						messages = i18n.messages[locale][keys[i]];
					} else {
						messages = messages[keys[i]];
					}
				}
			}
		}
	}
	return wording;
};

const onlyNumber = function (event) {
	let keyCode = event.keyCode ? event.keyCode : event.which;
	if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
		// 46 is dot
		event.preventDefault();
	}
};

const negativeNumber = function (event) {
	let keyCode = event.keyCode ? event.keyCode : event.which;
	if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode == 45 && event.target.selectionStart !== 0) {
		// 46 is dot
		event.preventDefault();
	}
};

const onlyInteger = function (event) {
	let keyCode = event.keyCode ? event.keyCode : event.which;
	if (keyCode < 48 || keyCode > 57) {
		// 46 is dot
		event.preventDefault();
	}
};

const setRequiredErrorBorder = function (state, required, vuetify_style) {
	let required_border = required == 1 ? (vuetify_style == 0 ? `border border-info` : `required-border`) : ``;
	let error_border = vuetify_style == 0 ? `border border-danger` : `error-border`;
	return state != false ? required_border : error_border;
};

const standardHeaderColWithoutDeliveryId = [
	{
		value: "main_salesman",
		text: i18n.t("COMMON.COL_MAINSALESMAN"),
		align: "center",
		sortable: true
	},
	{
		value: "salesman",
		text: i18n.t("COMMON.COL_SALESMAN"),
		align: "center",
		sortable: true
	},
	{
		value: "marking",
		text: i18n.t("COMMON.COL_MARKING"),
		align: "center",
		sortable: true
	},
	{
		value: "full_marking",
		text: i18n.t("COMMON.COL_FULL_MARKING"),
		align: "center",
		sortable: true
	},
	{
		value: "company_group",
		text: i18n.t("COMMON.COL_COMPANY_GRP"),
		align: "center",
		cellClass: "align-middle",
		sortable: false
	},
	{
		value: "customer_name",
		text: i18n.t("COMMON.COL_CUSTOMER_NAME"),
		align: "start",
		cellClass: "align-middle",
		sortable: false
	}
];

const standardHeaderColWithDeliveryId = [
	{
		value: "main_salesman",
		text: i18n.t("COMMON.COL_MAINSALESMAN"),
		customer_field: 1,
		align: "start text-nowrap"
	},
	{
		value: "salesman",
		text: i18n.t("COMMON.COL_SALESMAN"),
		customer_field: 1,
		align: "start text-nowrap"
	},
	{
		value: "marking",
		text: i18n.t("COMMON.COL_MARKING"),
		customer_field: 1,
		align: "start text-nowrap"
	},
	{
		value: "full_marking",
		text: i18n.t("COMMON.COL_FULL_MARKING"),
		customer_field: 1,
		align: "start text-nowrap"
	},
	{
		value: "company_group",
		text: i18n.t("COMMON.COL_COMPANY_GRP"),
		customer_field: 1,
		align: "start text-nowrap",
		cellClass: "align-middle",
		sortable: false
	},
	{
		value: "customer_name",
		text: i18n.t("COMMON.COL_CUSTOMER_NAME"),
		customer_field: 1,
		align: "start text-nowrap",
		cellClass: "align-middle",
		sortable: false
	},
	{
		value: "delivery_id",
		text: i18n.t("COMMON.COL_DELIVERY_ID"),
		align: "start text-nowrap",
		cellClass: "align-middle",
		sortable: false
	}
];

const unserializeJsonStr = function (value) {
	if (CoreSettingsJs.emptyStringValidation(value)) {
		return value;
	}
	return JSON.parse(value);
};

const UnixTimeToTime = function (unixtimestamp) {
	if (unixtimestamp == null) {
		return "";
	}
	//Convert timestamp to milliseconds
	var date = new Date(unixtimestamp);

	// Hours
	var hours = date.getHours();

	// Minutes
	var minutes = date.getMinutes();
	var time = hours + ":" + minutes;
	return time;
};

const enlargeProps = function (width = null, height = null) {
	let props = {
		center: true,
		fluidGrow: true,
		blank: false,
		blankColor: "#bbb",
		class: "mr-2 mb-2"
	};

	if (width != null) {
		props.width = width;
	}

	if (height != null) {
		props.height = height;
	}

	return props;
};

const ucword = function (value) {
	if (value == null) return null;
	value = value.toLowerCase();
	if (/\n/.test(value)) {
		return value
			.split("\n")
			.map((val1) =>
				val1
					.split(" ")
					.map((val) => val.charAt(0).toUpperCase() + val.slice(1))
					.join(" ")
			)
			.join("\n");
	} else {
		return value
			.split(" ")
			.map((val) => val.charAt(0).toUpperCase() + val.slice(1))
			.join(" ");
	}
};

const createPage = function (path) {
	return path.indexOf("create") !== -1 || path.indexOf("copy") !== -1 ? 1 : 0;
};

const copyPage = function (path) {
	return path.indexOf("copy") !== -1 ? 1 : 0;
};

const successMgsTitle = function (create_page) {
	return create_page == 1 ? "COMMON.MSG_SUCCESS_TO_CREATE" : "COMMON.MSG_SUCCESS_TO_UPDATE";
};

const failedMgsTitle = function (create_page) {
	return create_page == 1 ? "COMMON.MSG_FAILED_CREATE" : "COMMON.MSG_FAILED_TO_UPDATE";
};

const currencyRateHeaders = [
	{
		key: "name",
		label: i18n.t("MASTER_DATA.CURRENCIES.COL_EXCHANGE_CURRENCY"),
		class: "text-center align-middle"
	},
	{
		key: "limit_tt",
		label: i18n.t("MASTER_DATA.CURRENCIES.COL_LIMIT_TT"),
		class: "text-center inputColumn"
	},
	{
		key: "effective_time",
		label: i18n.t("MASTER_DATA.CURRENCIES.COL_EFFECTIVE_TIME"),
		class: "text-center inputColumn"
	},
	{
		key: "factor",
		label: i18n.t("MASTER_DATA.CURRENCIES.COL_CONVERSION_FACTOR"),
		class: "text-center inputColumn w-10"
	},
	{
		key: "status",
		label: i18n.t("COMMON.COL_STATUS"),
		class: "text-center"
	},
	{
		key: "rate",
		label: i18n.t("MASTER_DATA.CURRENCIES.COL_RATE"),
		class: "text-center inputColumn"
	}
];

function roundRectQR(ctx, x, y, width, height, radius, fill, stroke) {
	if (typeof stroke == "undefined") {
		stroke = true;
	}
	if (typeof radius === "undefined") {
		radius = 5;
	}
	ctx.beginPath();
	ctx.moveTo(x + radius, y);
	ctx.lineTo(x + width - radius, y);
	ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
	ctx.lineTo(x + width, y + height - radius);
	ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
	ctx.lineTo(x + radius, y + height);
	ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
	ctx.lineTo(x, y + radius);
	ctx.quadraticCurveTo(x, y, x + radius, y);
	ctx.closePath();
	if (stroke) {
		ctx.stroke();
	}
	if (fill) {
		ctx.fill();
	}
}

const swalOptions = function (custom_option) {
	let default_option = {
		title: i18n.t("COMMON.MSG.ARE_YOU_SURE"),
		text: null,
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		cancelButtonColor: "#d33"
	};
	let option = Object.assign({}, default_option, custom_option);
	return option;
};


const selectRowHandle = function (data) {
	return CoreSettingsJs.emptyObjectValidation(data) ? {} : data[0];
};

const dataTableHeightHandle = function (element = null, isForm = false) {
	let height = 300;
	if (element == null) {
		const _element = document.querySelector("#kt_content");
		if (_element != null) {
			const card_header = document.querySelector(isForm ? "#formHeader" : "#gsumary_card_header");
			const card_header_size = card_header != null ? Math.ceil(card_header.getBoundingClientRect().height) : 0;
			const gbizsContentSize = Math.floor(_element.getBoundingClientRect().height);

			const dataTable = document.querySelector("div.vdatable");
			const preSiblingSize = dataTable != null ? Math.ceil(dataTable.previousElementSibling != null ? dataTable.previousElementSibling.getBoundingClientRect().height : 0) : 0;
			const footerSize = dataTable != null ? Math.ceil(dataTable.children[0].nextElementSibling != null ? dataTable.children[0].nextElementSibling.getBoundingClientRect().height : 0) : 0;
			height = gbizsContentSize - card_header_size - preSiblingSize - footerSize - 50;
		}
	} else {

		const _element = document.querySelector(`#${element}`);
		const gbizsContentSize = Math.floor(_element.getBoundingClientRect().height);

		const dataTable = document.querySelector("div.vdatable");
		const preSiblingSize = dataTable != null ? Math.ceil(dataTable.previousElementSibling != null ? dataTable.previousElementSibling.getBoundingClientRect().height : 0) : 0;
		const footerSize = dataTable != null ? Math.ceil(dataTable.children[0].nextElementSibling != null ? dataTable.children[0].nextElementSibling.getBoundingClientRect().height : 0) : 0;
		height = gbizsContentSize - preSiblingSize - footerSize;
	}

	return height;
};

const successMsgHandle = function (create_page, module_name) {
	let title = create_page == 1 ? "COMMON.MSG_SUCCESS_TO_CREATE" : "COMMON.MSG_SUCCESS_TO_UPDATE";
	return i18n.t(title, {
		mod: module_name
	});
};

const failedMgsHandle = function (create_page, module_name) {
	let title = create_page == 1 ? "COMMON.MSG_FAILED_CREATE" : "COMMON.MSG_FAILED_TO_UPDATE";
	return i18n.t(title, {
		mod: module_name
	});
};

const requiredMsgHandle = function (field_name) {
	return i18n.t("COMMON.VALIDATION.REQUIRED", {
		name: field_name
	});
};

const ktContentScroll = function (x, y) {
	document.querySelector("#kt_content").scrollTo(x, y - 100);
};

const placeHolderHandle = function (field_name) {
	return i18n.t("COMMON.PH_ENTER_TEXTBOX", {
		txt_fieldname: field_name
	});
};

const currentTimeStamp = function () {
	return UnixTimeToDateTime(new Date());
};

const textColor = function (hex) {
	if (hex == null) return "#000";
	let c = hex.substring(1).split("");
	if (c.length == 3) {
		c = [c[0], c[0], c[1], c[1], c[2], c[2]];
	}
	c = "0x" + c.join("");
	let rgb = "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)";
	let a = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/),
		r = a[1],
		g = a[2],
		b = a[3];
	var hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
	return hsp > 170 ? "#000" : "#fff";
};

const colorIndicatorStyle = function (bgColor, textColor, boxType = 0) {
	if (bgColor == null) bgColor = "#fff";
	return {
		backgroundColor: bgColor,
		border: bgColor == "#FFFFFF" ? "solid 1px #c7c5c5" : "none",
		cursor: "pointer",
		height: boxType == 2 ? "20px" : "26px",
		padding: "0 10px",
		"line-height": "1.9",
		color: textColor,
		"text-align": boxType == 0 ? "left" : "center",
		transition: "border-radius 200ms ease-in-out",
		"font-weight": "900",
		"margin-bottom": boxType == 2 ? "3px" : "0"
	};
};

const confirmationBox = async function (title, icon = "info", cancel_btn = i18n.t("COMMON.BTN_CANCEL"), confirm_btn = i18n.t("COMMON.BTN_CONFIRM"), msg = null) {
	let response = await Swal.fire({
		title: CoreSettingsJs.emptyObjectValidation(msg) ? i18n.t("COMMON.MSG.ARE_YOU_SURE") : title,
		html: CoreSettingsJs.emptyObjectValidation(msg) ? title : msg,
		icon: icon,
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		cancelButtonColor: "#d33",
		cancelButtonText: cancel_btn,
		confirmButtonText: confirm_btn
	});
	return response;
};

const resetState = function (elem, state_var, idx = null) {
	if (idx != null) elem[idx][`${state_var}`] = null;
	elem[`${state_var}`] = null;
};

const summaryPageRowActionValidation = function (data) {
	return !CoreSettingsJs.emptyObjectValidation(data) ? data[0] : {};
};

const removeHtmlTags = function (data) {
	return data.replace(/(<([^>]+)>)/gi, "");
};

export default {
	resetSelectionValue,
	resetSelectionObject,
	colorIndicatorStyle,
	confirmationBox,
	copyPage,
	createPage,
	currencyRateHeaders,
	currentTimeStamp,
	dataTableHeightHandle,
	dimensionFormat,
	editorSettings,
	enlargeProps,
	epo_status_options,
	expireBadgeHandle,
	expireValidHandle,
	failedMgsHandle,
	failedMgsTitle,
	filterActionField,
	formula_options,
	getStatusBadge,
	getStatusName,
	getWordingFromi18n,
	getYesNo,
	IntegerChecking,
	ktContentScroll,
	negativeNumber,
	numberChecking,
	numberDecimal,
	numberFormatCurrency,
	numberFormatInteger,
	numberFormatM3,
	NumberFormatToInteger,
	numberHandle,
	onFormatterAccountNo,
	onFormatterLowerCase,
	onFormatterPhoneNumber,
	onFormatterUpperCase,
	onlyInteger,
	onlyNumber,
	onPressPhoneNumber,
	placeHolderHandle,
	removeHtmlTags,
	requiredMsgHandle,
	resetObServer,
	resetOptionsValue,
	resetState,
	roundRectQR,
	selectRowHandle,
	setLanguageRecord,
	setRequiredErrorBorder,
	standardHeaderColWithDeliveryId,
	standardHeaderColWithoutDeliveryId,
	successMgsTitle,
	successMsgHandle,
	summaryPageRowActionValidation,
	swalOptions,
	textColor,
	ucfirst,
	ucword,
	UnixTimeToDate,
	UnixTimeToDateTime,
	UnixTimeToTime,
	UnixTimeToYear,
	UnixTimeToYM,
	unserializeJsonStr,
	validateContactNo,
	validateEmail,
	viewPage,
};
