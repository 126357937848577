import ApiService from "@/core/services/api.service";
import CoreSettingsJs from "@shared/src/core/services/settings.js";

//action type
export const GET_SYS_SETTINGS = "sysSettings";
export const GET_LOCALE = "locale";

//mutation types
export const SET_SYS_SETTINGS = "setSettings";
export const SET_LOCALE = "setLocale";

const state = {
	settings: {
		quotation: {
			skip_confirm_lcl_condition: 0,
			skip_confirm_fcl_condition: 0
		},
		defaultCurrency: null
	},
	locales: []
};

const getters = {
	currentSettings(state) {
		return state.sys_settings;
	},
	currentLocale(state) {
		return state.locales;
	},
	isHq(state) {
		let settings = state.sys_settings;
		return !CoreSettingsJs.emptyObjectValidation(settings.sys_settings.is_master) ? 1 : 0;
	}
};

const actions = {
	[GET_SYS_SETTINGS](context) {
		return new Promise((resolve) => {
			ApiService.get("settings/sys-setting").then((response) => {
				let data = response.data;
				if (data.code == 200) {
					// let result = data.result;
					let newData = data.result;
					context.commit(SET_SYS_SETTINGS, newData);
					resolve(data);
				}
			});
		});
	},
	[GET_LOCALE](context) {
		return new Promise((resolve) => {
			ApiService.get("locale/list").then((response) => {
				let data = response.data;
				if (data.code == 200) {
					context.commit(SET_LOCALE, data.result);
					resolve(data);
				}
			});
		});
	}
};

const mutations = {
	[SET_SYS_SETTINGS](state, settings) {
		state.sys_settings = settings;
	},
	[SET_LOCALE](state, localeList) {
		state.locales = localeList;
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};
