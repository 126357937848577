export default {
	state: {
		epoSearchForm: sessionStorage.epoSearchForm ? JSON.parse(sessionStorage.epoSearchForm) : null,
		invSearchForm: sessionStorage.invSearchForm ? JSON.parse(sessionStorage.invSearchForm) : null,
		//Add On State Variable
		deliveryAddrSearchForm: sessionStorage.deliveryAddrSearchForm ? JSON.parse(sessionStorage.deliveryAddrSearchForm) : null,
		customerSearchForm: sessionStorage.customerSearchForm ? JSON.parse(sessionStorage.customerSearchForm) : null,
		docNumSearchForm: sessionStorage.docNumSearchForm ? JSON.parse(sessionStorage.docNumSearchForm) : null,
		companySearchForm: sessionStorage.companySearchForm ? JSON.parse(sessionStorage.companySearchForm) : null,
		warehouseSearchForm: sessionStorage.warehouseSearchForm ? JSON.parse(sessionStorage.warehouseSearchForm) : null,
		warehouseLocationSearchForm: sessionStorage.warehouseLocationSearchForm ? JSON.parse(sessionStorage.warehouseLocationSearchForm) : null,
		warehouseTypeSearchForm: sessionStorage.warehouseTypeSearchForm ? JSON.parse(sessionStorage.warehouseTypeSearchForm) : null,
		countriesSearchForm: sessionStorage.countriesSearchForm ? JSON.parse(sessionStorage.countriesSearchForm) : null,
		stateSearchForm: sessionStorage.stateSearchForm ? JSON.parse(sessionStorage.stateSearchForm) : null,
		citiesSearchForm: sessionStorage.citiesSearchForm ? JSON.parse(sessionStorage.citiesSearchForm) : null,
		districtSearchForm: sessionStorage.districtSearchForm ? JSON.parse(sessionStorage.districtSearchForm) : null,
		postcodeSearchForm: sessionStorage.postcodeSearchForm ? JSON.parse(sessionStorage.postcodeSearchForm) : null,
		locationSearchForm: sessionStorage.locationSearchForm ? JSON.parse(sessionStorage.locationSearchForm) : null,
		portSearchForm: sessionStorage.portSearchForm ? JSON.parse(sessionStorage.portSearchForm) : null,
		territorySearchForm: sessionStorage.territorySearchForm ? JSON.parse(sessionStorage.territorySearchForm) : null,
		deliveryZoneSearchForm: sessionStorage.deliveryZoneSearchForm ? JSON.parse(sessionStorage.deliveryZoneSearchForm) : null,
		currencyGroupSearchForm: sessionStorage.currencyGroupSearchForm ? JSON.parse(sessionStorage.currencyGroupSearchForm) : null,
		currenciesSearchForm: sessionStorage.currenciesSearchForm ? JSON.parse(sessionStorage.currenciesSearchForm) : null,
		taxTypeSearchForm: sessionStorage.taxTypeSearchForm ? JSON.parse(sessionStorage.taxTypeSearchForm) : null,
		taxSerarchForm: sessionStorage.taxSerarchForm ? JSON.parse(sessionStorage.taxSerarchForm) : null,
		shipmentTypeSearchForm: sessionStorage.shipmentTypeSearchForm ? JSON.parse(sessionStorage.shipmentTypeSearchForm) : null,
		shipmentModeSearchForm: sessionStorage.shipmentModeSearchForm ? JSON.parse(sessionStorage.shipmentModeSearchForm) : null,
		movementTypeSearchForm: sessionStorage.movementTypeSearchForm ? JSON.parse(sessionStorage.movementTypeSearchForm) : null,
		shipmentTermsSearchForm: sessionStorage.shipmentTermsSearchForm ? JSON.parse(sessionStorage.shipmentTermsSearchForm) : null,
		referenceTypeSearchForm: sessionStorage.referenceTypeSearchForm ? JSON.parse(sessionStorage.referenceTypeSearchForm) : null,
		courierCompanySearchForm: sessionStorage.courierCompanySearchForm ? JSON.parse(sessionStorage.courierCompanySearchForm) : null,
		deliveryAgentSearchForm: sessionStorage.deliveryAgentSearchForm ? JSON.parse(sessionStorage.deliveryAgentSearchForm) : null,
		forwarderGroupSearchForm: sessionStorage.forwarderGroupSearchForm ? JSON.parse(sessionStorage.forwarderGroupSearchForm) : null,
		forwarderSearchForm: sessionStorage.forwarderSearchForm ? JSON.parse(sessionStorage.forwarderSearchForm) : null,
		shipperSearchForm: sessionStorage.shipperSearchForm ? JSON.parse(sessionStorage.shipperSearchForm) : null,
		consigneeGroupSearchForm: sessionStorage.consigneeGroupSearchForm ? JSON.parse(sessionStorage.consigneeGroupSearchForm) : null,
		consigneeSearchForm: sessionStorage.consigneeSearchForm ? JSON.parse(sessionStorage.consigneeSearchForm) : null,
		shippingLineSearchForm: sessionStorage.shippingLineSearchForm ? JSON.parse(sessionStorage.shippingLineSearchForm) : null,
		transpoterSearchForm: sessionStorage.transpoterSearchForm ? JSON.parse(sessionStorage.transpoterSearchForm) : null,
		chargesTypeSearchForm: sessionStorage.chargesTypeSearchForm ? JSON.parse(sessionStorage.chargesTypeSearchForm) : null,
		noticeTypeGroupSearchType: sessionStorage.noticeTypeGroupSearchType ? JSON.parse(sessionStorage.noticeTypeGroupSearchType) : null,
		noticeTYpeSearchForm: sessionStorage.noticeTYpeSearchForm ? JSON.parse(sessionStorage.noticeTYpeSearchForm) : null,
		docTypeSearchForm: sessionStorage.docTypeSearchForm ? JSON.parse(sessionStorage.docTypeSearchForm) : null,
		stockUomSearchForm: sessionStorage.stockUomSearchForm ? JSON.parse(sessionStorage.stockUomSearchForm) : null,
		instructionTypeSearchForm: sessionStorage.instructionTypeSearchForm ? JSON.parse(sessionStorage.instructionTypeSearchForm) : null,
		payModeSearchFrorm: sessionStorage.payModeSearchFrorm ? JSON.parse(sessionStorage.payModeSearchFrorm) : null,
		ttDailyRateSearchForm: sessionStorage.ttDailyRateSearchForm ? JSON.parse(sessionStorage.ttDailyRateSearchForm) : null,
		ttChargesSearchForm: sessionStorage.ttChargesSearchForm ? JSON.parse(sessionStorage.ttChargesSearchForm) : null,
		beneficiaryTypeSearchForm: sessionStorage.beneficiaryTypeSearchForm ? JSON.parse(sessionStorage.beneficiaryTypeSearchForm) : null,
		ttCategorySearchFForm: sessionStorage.ttCategorySearchFForm ? JSON.parse(sessionStorage.ttCategorySearchFForm) : null,
		ttGroupSearchForm: sessionStorage.ttGroupSearchForm ? JSON.parse(sessionStorage.ttGroupSearchForm) : null,
		bankCodeSearchForm: sessionStorage.bankCodeSearchForm ? JSON.parse(sessionStorage.bankCodeSearchForm) : null,
		ttCreditorSearchForm: sessionStorage.ttCreditorSearchForm ? JSON.parse(sessionStorage.ttCreditorSearchForm) : null,
		ttGLAccountSearchForm: sessionStorage.ttGLAccountSearchForm ? JSON.parse(sessionStorage.ttGLAccountSearchForm) : null,
		ttDefaultGLAccountSearchForm: sessionStorage.ttDefaultGLAccountSearchForm ? JSON.parse(sessionStorage.ttDefaultGLAccountSearchForm) : null,
		ttAgentSearchForm: sessionStorage.ttAgentSearchForm ? JSON.parse(sessionStorage.ttAgentSearchForm) : null,
		ttCompanySearchForm: sessionStorage.ttCompanySearchForm ? JSON.parse(sessionStorage.ttCompanySearchForm) : null,
		ttRejectReasonSearchForm: sessionStorage.ttRejectReasonSearchForm ? JSON.parse(sessionStorage.ttRejectReasonSearchForm) : null,
		ttSpreadRateSearchForm: sessionStorage.ttSpreadRateSearchForm ? JSON.parse(sessionStorage.ttSpreadRateSearchForm) : null,
		creditTermSearchForm: sessionStorage.creditTermSearchForm ? JSON.parse(sessionStorage.creditTermSearchForm) : null,
		industriesSearchForm: sessionStorage.industriesSearchForm ? JSON.parse(sessionStorage.industriesSearchForm) : null,
		partnerTypeSearchForm: sessionStorage.partnerTypeSearchForm ? JSON.parse(sessionStorage.partnerTypeSearchForm) : null,
		deliveryRemarkSearchForm: sessionStorage.deliveryRemarkSearchForm ? JSON.parse(sessionStorage.deliveryRemarkSearchForm) : null,
		rejectChangeACRequestReasonSearchForm: sessionStorage.rejectChangeACRequestReasonSearchForm ? JSON.parse(sessionStorage.rejectChangeACRequestReasonSearchForm) : null,
		forwarderCostingTypeSearchForm: sessionStorage.forwarderCostingTypeSearchForm ? JSON.parse(sessionStorage.forwarderCostingTypeSearchForm) : null,
		glAccountSettingSearchForm: sessionStorage.glAccountSettingSearchForm ? JSON.parse(sessionStorage.glAccountSettingSearchForm) : null,
		validitySearchForm: sessionStorage.validitySearchForm ? JSON.parse(sessionStorage.validitySearchForm) : null,
		indemnifySearchForm: sessionStorage.indemnifySearchForm ? JSON.parse(sessionStorage.indemnifySearchForm) : null,
		freightChargesSearchForm: sessionStorage.freightChargesSearchForm ? JSON.parse(sessionStorage.freightChargesSearchForm) : null,
		quotationSubjectSearchForm: sessionStorage.quotationSubjectSearchForm ? JSON.parse(sessionStorage.quotationSubjectSearchForm) : null,
		customerRejectReasonSearachForm: sessionStorage.customerRejectReasonSearachForm ? JSON.parse(sessionStorage.customerRejectReasonSearachForm) : null,
		adminRejectReasonSearchForm: sessionStorage.adminRejectReasonSearchForm ? JSON.parse(sessionStorage.adminRejectReasonSearchForm) : null,
		revertReasonSearchForm: sessionStorage.revertReasonSearchForm ? JSON.parse(sessionStorage.revertReasonSearchForm) : null,
		invalidReasonSearchForm: sessionStorage.invalidReasonSearchForm ? JSON.parse(sessionStorage.invalidReasonSearchForm) : null,
		specialProductSearchForm: sessionStorage.specialProductSearchForm ? JSON.parse(sessionStorage.specialProductSearchForm) : null,
		specialProductRemarkSearchForm: sessionStorage.specialProductRemarkSearchForm ? JSON.parse(sessionStorage.specialProductRemarkSearchForm) : null,
		catTypeCostSearchForm: sessionStorage.catTypeCostSearchForm ? JSON.parse(sessionStorage.catTypeCostSearchForm) : null

	},
	getters: {
	},
	actions: {
		setEpoSearchForm(state, payload) {
			state.commit("setEpoSearchForm", payload);
		},
		setInvSearchForm(state, payload) {
			state.commit("setInvSearchForm", payload);
		},
		//Add Action
		setDeliveryAddrSearchForm(state, payload) {
			state.commit("setDeliveryAddrSearchForm", payload)
		},
		setCustomerSearchForm(state, payload) {
			state.commit("setCustomerSearchForm", payload)
		},
		setDocNumSearchForm(state, payload) {
			state.commit("setDocNumSearchForm", payload)
		},
		setCompanySearchForm(state, payload) {
			state.commit("setCompanySearchForm", payload)
		},
		setWarehouseSearchForm(state, payload) {
			state.commit("setWarehouseSearchForm", payload)
		},
		setWarehouseLocationSearchForm(state, payload) {
			state.commit("setWarehouseLocationSearchForm", payload)
		},
		setWarehouseTypeSearchForm(state, payload) {
			state.commit("setWarehouseTypeSearchForm", payload)
		},
		setCountriesSearchForm(state, payload) {
			state.commit("setCountriesSearchForm", payload)
		},
		setStateSearchForm(state, payload) {
			state.commit("setStateSearchForm", payload)
		},
		setCitiesSearchForm(state, payload) {
			state.commit("setCitiesSearchForm", payload)
		},
		setDistrictSearchForm(state, payload) {
			state.commit("setDistrictSearchForm", payload)
		},
		setPostcodesSearchForm(state, payload) {
			state.commit("setPostcodesSearchForm", payload);
		},
		setLocationSearchForm(state, payload) {
			state.commit("setLocationSearchForm", payload);
		},
		setPortSearchForm(state, payload) {
			state.commit("setPortSearchForm", payload);
		},
		setTerritorySearchForm(state, payload) {
			state.commit("setTerritorySearchForm", payload);
		},
		setDeliveryZoneSearchForm(state, payload) {
			state.commit("setDeliveryZoneSearchForm", payload);
		},
		setCurrencyGroupSearchForm(state, payload) {
			state.commit("setCurrencyGroupSearchForm", payload);
		},
		setCurrenciesSearchForm(state, payload) {
			state.commit("setCurrenciesSearchForm", payload);
		},
		setTaxTypeSearchForm(state, payload) {
			state.commit("setTaxTypeSearchForm", payload);
		},
		setTaxSearchForm(state, payload) {
			state.commit("setTaxSearchForm", payload);
		},
		setShipmentTypeSearchForm(state, payload) {
			state.commit("setShipmentTypeSearchForm", payload);
		},
		setShipmentModeSearchForm(state, payload) {
			state.commit("setShipmentModeSearchForm", payload);
		},
		setMovementTypeSearchForm(state, payload) {
			state.commit("setMovementTypeSearchForm", payload);
		},
		setShipmentTermSearchForm(state, payload) {
			state.commit("setShipmentTermSearchForm", payload);
		},
		setReferenceTypeSearchForm(state, payload) {
			state.commit("setReferenceTypeSearchForm", payload);
		},
		setCourierCompanySearchForm(state, payload) {
			state.commit("setCourierCompanySearchForm", payload);
		},
		setDeliveryAgentSearchForm(state, payload) {
			state.commit("setDeliveryAgentSearchForm", payload);
		},
		setForwarderGroupSearchForm(state, payload) {
			state.commit("setForwarderGroupSearchForm", payload);
		},
		setForwarderSearchForm(state, payload) {
			state.commit("setForwarderSearchForm", payload);
		},
		setShipperSearchForm(state, payload) {
			state.commit("setShipperSearchForm", payload);
		},
		setConsigneeGroupSearchForm(state, payload) {
			state.commit("setConsigneeGroupSearchForm", payload);
		},
		setConsigneeSearchForm(state, payload) {
			state.commit("setConsigneeSearchForm", payload);
		},
		setShippingLineSearchForm(state, payload) {
			state.commit("setShippingLineSearchForm", payload);
		},
		setTranspoterSearchLine(state, payload) {
			state.commit("setTranspoterSearchLine", payload)
		},
		setChargesTypeSearchForm(state, payload) {
			state.commit("setChargesTypeSearchForm", payload)
		},
		setNoticeTypeGroupSearchForm(state, payload) {
			state.commit("setNoticeTypeGroupSearchForm", payload)
		},
		setNoticeTypeSearchForm(state, payload) {
			state.commit("setNoticeTypeSearchForm", payload)
		},
		setDocTypeSearchForm(state, payload) {
			state.commit("setDocTypeSearchForm", payload)
		},
		setStockUomSearchForm(state, payload) {
			state.commit("setStockUomSearchForm", payload)
		},
		setInstructionTypeSearchForm(state, payload) {
			state.commit("setInstructionTypeSearchForm", payload)
		},
		setPayModeSearchForm(state, payload) {
			state.commit("setPayModeSearchForm", payload)
		},
		setTtDailyRateSearchForm(state, payload) {
			state.commit("setTtDailyRateSearchForm", payload)
		},
		setTtChargesSearchForm(state, payload) {
			state.commit("setTtChargesSearchForm", payload)
		},
		setBeneficiaryTypeSearchForm(state, payload) {
			state.commit("setBeneficiaryTypeSearchForm", payload)
		},
		setTtCategorySearchForm(state, payload) {
			state.commit("setTtCategorySearchForm", payload)
		},
		setTtGroupSearchForm(state, payload) {
			state.commit("setTtGroupSearchForm", payload);
		},
		setBankCodeSearchForm(state, payload) {
			state.commit("setBankCodeSearchForm", payload);
		},
		setTtCreditorSearchForm(state, payload) {
			state.commit("setTtCreditorSearchForm", payload);
		},
		setTtGLAccountSearchForm(state, payload) {
			state.commit("setTtGLAccountSearchForm", payload);
		},
		setTtDefaultGLAccountSearchForm(state, payload) {
			state.commit("setTtDefaultGLAccountSearchForm", payload);
		},
		setTtAgentSearchForm(state, payload) {
			state.commit("setTtAgentSearchForm", payload);
		},
		setTtCompanySearchForm(state, payload) {
			state.commit("setTtCompanySearchForm", payload);
		},
		setTtRejectReasonSearchForm(state, payload) {
			state.commit("setTtRejectReasonSearchForm", payload);
		},
		setSpreadRateSearchForm(state, payload) {
			state.commit("setSpreadRateSearchForm", payload);
		},
		setCreditTermSearchForm(state, payload) {
			state.commit("setCreditTermSearchForm", payload);
		},
		setIndustriesSearchForm(state, payload) {
			state.commit("setIndustriesSearchForm", payload);
		},
		setPartnerTypeSearchForm(state, payload) {
			state.commit("setPartnerTypeSearchForm", payload);
		},
		setDeliveryRemarkSearchForm(state, payload) {
			state.commit("setDeliveryRemarkSearchForm", payload);
		},
		setRejectChangeACRequestReasonSearchForm(state, payload) {
			state.commit("setRejectChangeACRequestReasonSearchForm", payload);
		},
		setForwarderCostingTypeSearchForm(state, payload) {
			state.commit("setForwarderCostingTypeSearchForm", payload);
		},
		setGlAccountSettingSearchForm(state, payload) {
			state.commit("setGlAccountSettingSearchForm", payload);
		},
		setValiditySearchForm(state, payload) {
			state.commit("setValiditySearchForm", payload);
		},
		setIndemnifySearchForm(state, payload) {
			state.commit("setIndemnifySearchForm", payload);
		},
		setFreightChargesSearchForm(state, payload) {
			state.commit("setFreightChargesSearchForm", payload);
		},
		setQuotationSubjectSearchForm(state, payload) {
			state.commit("setQuotationSubjectSearchForm", payload);
		},
		setCusotmerRejectReasonSearchForm(state, payload) {
			state.commit("setCusotmerRejectReasonSearchForm", payload)
		},
		setAdminRejectReasonSearchForm(state, payload) {
			state.commit("setAdminRejectReasonSearchForm", payload)
		},
		setRevertReasonSearchForm(state, payload) {
			state.commit("setRevertReasonSearchForm", payload)
		},
		invalidReasonSearchForm(state, payload) {
			state.commit("invalidReasonSearchForm", payload)
		},
		setSpecialProductSearchForm(state, payload) {
			state.commit("setSpecialProductSearchForm", payload)
		},
		setSpecialProductRemarkSearchForm(state, payload) {
			state.commit("setSpecialProductRemarkSearchForm", payload)
		},
		setCatTypeCostSearchForm(state, payload) {
			state.commit("setCatTypeCostSearchForm", payload);
		}
	},
	mutations: {
		setEpoSearchForm(state, payload) {
			state.epoSearchForm = payload;
			sessionStorage.epoSearchForm = JSON.stringify(payload);
		},
		setInvSearchForm(state, payload) {
			state.invSearchForm = payload;
			sessionStorage.invSearchForm = JSON.stringify(payload);
		},
		setDeliveryAddrSearchForm(state, payload) {
			state.deliveryAddrSearchForm = payload;
			sessionStorage.deliveryAddrSearchForm = JSON.stringify(payload);
		},
		setCustomerSearchForm(state, payload) {
			state.customerSearchForm = payload;
			sessionStorage.customerSearchForm = JSON.stringify(payload);
		},
		setDocNumSearchForm(state, payload) {
			state.docNumSearchForm = payload;
			sessionStorage.docNumSearchForm = JSON.stringify(payload);
		},
		setCompanySearchForm(state, payload) {
			state.companySearchForm = payload;
			sessionStorage.companySearchForm = JSON.stringify(payload);
		},
		setWarehouseSearchForm(state, payload) {
			state.warehouseSearchForm = payload;
			sessionStorage.warehouseSearchForm = JSON.stringify(payload);
		},
		setWarehouseLocationSearchForm(state, payload) {
			state.warehouseLocationSearchForm = payload;
			sessionStorage.warehouseLocationSearchForm = JSON.stringify(payload);
		},
		setWarehouseTypeSearchForm(state, payload) {
			state.warehouseTypeSearchForm = payload;
			sessionStorage.warehouseTypeSearchForm = JSON.stringify(payload);
		},
		setCountriesSearchForm(state, payload) {
			state.countriesSearchForm = payload;
			sessionStorage.countriesSearchForm = JSON.stringify(payload);
		},
		setStateSearchForm(state, payload) {
			state.stateSearchForm = payload;
			sessionStorage.stateSearchForm = JSON.stringify(payload);
		},
		setCitiesSearchForm(state, payload) {
			state.citiesSearchForm = payload;
			sessionStorage.citiesSearchForm = JSON.stringify(payload);
		},
		setDistrictSearchForm(state, payload) {
			state.districtSearchForm = payload;
			sessionStorage.districtSearchForm = JSON.stringify(payload);
		},
		setPostcodesSearchForm(state, payload) {
			state.postcodeSearchForm = payload;
			sessionStorage.postcodeSearchForm = JSON.stringify(payload);
		},
		setLocationSearchForm(state, payload) {
			state.locationSearchForm = payload;
			sessionStorage.locationSearchForm = JSON.stringify(payload);
		},
		setPortSearchForm(state, payload) {
			state.portSearchForm = payload;
			sessionStorage.portSearchForm = JSON.stringify(payload);
		},
		setTerritorySearchForm(state, payload) {
			state.territorySearchForm = payload;
			sessionStorage.territorySearchForm = JSON.stringify(payload);
		},
		setDeliveryZoneSearchForm(state, payload) {
			state.deliveryZoneSearchForm = payload;
			sessionStorage.deliveryZoneSearchForm = JSON.stringify(payload);
		},
		setCurrencyGroupSearchForm(state, payload) {
			state.currencyGroupSearchForm = payload;
			sessionStorage.currencyGroupSearchForm = JSON.stringify(payload);
		},
		setCurrenciesSearchForm(state, payload) {
			state.currenciesSearchForm = payload;
			sessionStorage.currenciesSearchForm = JSON.stringify(payload);
		},
		setTaxTypeSearchForm(state, payload) {
			state.taxTypeSearchForm = payload;
			sessionStorage.taxTypeSearchForm = JSON.stringify(payload);
		},
		setTaxSearchForm(state, payload) {
			state.taxSerarchForm = payload;
			sessionStorage.taxSerarchForm = JSON.stringify(payload);
		},
		setShipmentTypeSearchForm(state, payload) {
			state.shipmentTypeSearchForm = payload;
			sessionStorage.shipmentTypeSearchForm = JSON.stringify(payload);
		},
		setShipmentModeSearchForm(state, payload) {
			state.shipmentModeSearchForm = payload;
			sessionStorage.shipmentModeSearchForm = JSON.stringify(payload);
		},
		setMovementTypeSearchForm(state, payload) {
			state.movementTypeSearchForm = payload;
			sessionStorage.movementTypeSearchForm = JSON.stringify(payload);
		},
		setShipmentTermSearchForm(state, payload) {
			state.shipmentTermsSearchForm = payload;
			sessionStorage.shipmentTermsSearchForm = JSON.stringify(payload);
		},
		setReferenceTypeSearchForm(state, payload) {
			state.referenceTypeSearchForm = payload;
			sessionStorage.referenceTypeSearchForm = JSON.stringify(payload);
		},
		setCourierCompanySearchForm(state, payload) {
			state.courierCompanySearchForm = payload;
			sessionStorage.courierCompanySearchForm = JSON.stringify(payload);
		},
		setDeliveryAgentSearchForm(state, payload) {
			state.deliveryAgentSearchForm = payload;
			sessionStorage.deliveryAgentSearchForm = JSON.stringify(payload);
		},
		setForwarderGroupSearchForm(state, payload) {
			state.forwarderGroupSearchForm = payload;
			sessionStorage.forwarderGroupSearchForm = JSON.stringify(payload);
		},
		setForwarderSearchForm(state, payload) {
			state.forwarderSearchForm = payload;
			sessionStorage.forwarderSearchForm = JSON.stringify(payload);
		},
		setShipperSearchForm(state, payload) {
			state.shipperSearchForm = payload;
			sessionStorage.shipperSearchForm = JSON.stringify(payload);
		},
		setConsigneeGroupSearchForm(state, payload) {
			state.consigneeGroupSearchForm = payload;
			sessionStorage.consigneeGroupSearchForm = JSON.stringify(payload);
		},
		setConsigneeSearchForm(state, payload) {
			state.consigneeSearchForm = payload;
			sessionStorage.consigneeSearchForm = JSON.stringify(payload);
		},
		setShippingLineSearchForm(state, payload) {
			state.shippingLineSearchForm = payload;
			sessionStorage.shippingLineSearchForm = JSON.stringify(payload);
		},
		setTranspoterSearchLine(state, payload) {
			state.transpoterSearchForm = payload;
			sessionStorage.transpoterSearchForm = JSON.stringify(payload);
		},
		setChargesTypeSearchForm(state, payload) {
			state.chargesTypeSearchForm = payload;
			sessionStorage.chargesTypeSearchForm = JSON.stringify(payload);
		},
		setNoticeTypeGroupSearchForm(state, payload) {
			state.noticeTypeGroupSearchType = payload;
			sessionStorage.noticeTypeGroupSearchType = JSON.stringify(payload);
		},
		setNoticeTypeSearchForm(state, payload) {
			state.noticeTYpeSearchForm = payload;
			sessionStorage.noticeTYpeSearchForm = JSON.stringify(payload);
		},
		setDocTypeSearchForm(state, payload) {
			state.docTypeSearchForm = payload;
			sessionStorage.docTypeSearchForm = JSON.stringify(payload);
		},
		setStockUomSearchForm(state, payload) {
			state.stockUomSearchForm = payload;
			sessionStorage.stockUomSearchForm = JSON.stringify(payload);
		},
		setInstructionTypeSearchForm(state, payload) {
			state.instructionTypeSearchForm = payload;
			sessionStorage.instructionTypeSearchForm = JSON.stringify(payload);
		},
		setPayModeSearchForm(state, payload) {
			state.payModeSearchFrorm = payload;
			sessionStorage.payModeSearchFrorm = JSON.stringify(payload);
		},
		setTtDailyRateSearchForm(state, payload) {
			state.ttDailyRateSearchForm = payload;
			sessionStorage.ttDailyRateSearchForm = JSON.stringify(payload);
		},
		setTtChargesSearchForm(state, payload) {
			state.ttChargesSearchForm = payload;
			sessionStorage.ttChargesSearchForm = JSON.stringify(payload);
		},
		setBeneficiaryTypeSearchForm(state, payload) {
			state.beneficiaryTypeSearchForm = payload;
			sessionStorage.beneficiaryTypeSearchForm = JSON.stringify(payload);
		},
		setTtCategorySearchForm(state, payload) {
			state.ttCategorySearchFForm = payload;
			sessionStorage.ttCategorySearchFForm = JSON.stringify(payload);
		},
		setTtGroupSearchForm(state, payload) {
			state.ttGroupSearchForm = payload;
			sessionStorage.ttGroupSearchForm = JSON.stringify(payload);
		},
		setBankCodeSearchForm(state, payload) {
			state.bankCodeSearchForm = payload;
			sessionStorage.bankCodeSearchForm = JSON.stringify(payload);
		},
		setTtCreditorSearchForm(state, payload) {
			state.ttCreditorSearchForm = payload;
			sessionStorage.ttCreditorSearchForm = JSON.stringify(payload);
		},
		setTtGLAccountSearchForm(state, payload) {
			state.ttGLAccountSearchForm = payload;
			sessionStorage.ttGLAccountSearchForm = JSON.stringify(payload);
		},
		setTtDefaultGLAccountSearchForm(state, payload) {
			state.ttDefaultGLAccountSearchForm = payload;
			sessionStorage.ttDefaultGLAccountSearchForm = JSON.stringify(payload);
		},
		setTtAgentSearchForm(state, payload) {
			state.ttAgentSearchForm = payload;
			sessionStorage.ttAgentSearchForm = JSON.stringify(payload);
		},
		setTtCompanySearchForm(state, payload) {
			state.ttCompanySearchForm = payload;
			sessionStorage.ttCompanySearchForm = JSON.stringify(payload);
		},
		setTtRejectReasonSearchForm(state, payload) {
			state.ttRejectReasonSearchForm = payload;
			sessionStorage.ttRejectReasonSearchForm = JSON.stringify(payload);
		},
		setSpreadRateSearchForm(state, payload) {
			state.ttSpreadRateSearchForm = payload;
			sessionStorage.ttSpreadRateSearchForm = JSON.stringify(payload);
		},
		setCreditTermSearchForm(state, payload) {
			state.creditTermSearchForm = payload;
			sessionStorage.creditTermSearchForm = JSON.stringify(payload);
		},
		setIndustriesSearchForm(state, payload) {
			state.industriesSearchForm = payload;
			sessionStorage.industriesSearchForm = JSON.stringify(payload);
		},
		setPartnerTypeSearchForm(state, payload) {
			state.partnerTypeSearchForm = payload;
			sessionStorage.partnerTypeSearchForm = JSON.stringify(payload);
		},
		setDeliveryRemarkSearchForm(state, payload) {
			state.deliveryRemarkSearchForm = payload;
			sessionStorage.deliveryRemarkSearchForm = JSON.stringify(payload);
		},
		setRejectChangeACRequestReasonSearchForm(state, payload) {
			state.rejectChangeACRequestReasonSearchForm = payload;
			sessionStorage.rejectChangeACRequestReasonSearchForm = JSON.stringify(payload);
		},
		setForwarderCostingTypeSearchForm(state, payload) {
			state.forwarderCostingTypeSearchForm = payload;
			sessionStorage.forwarderCostingTypeSearchForm = JSON.stringify(payload);
		},
		setGlAccountSettingSearchForm(state, payload) {
			state.glAccountSettingSearchForm = payload;
			sessionStorage.glAccountSettingSearchForm = JSON.stringify(payload);
		},
		setValiditySearchForm(state, payload) {
			state.validitySearchForm = payload;
			sessionStorage.validitySearchForm = JSON.stringify(payload);
		},
		setIndemnifySearchForm(state, payload) {
			state.indemnifySearchForm = payload;
			sessionStorage.indemnifySearchForm = JSON.stringify(payload);
		},
		setFreightChargesSearchForm(state, payload) {
			state.freightChargesSearchForm = payload;
			sessionStorage.freightChargesSearchForm = JSON.stringify(payload);
		},
		setQuotationSubjectSearchForm(state, payload) {
			state.quotationSubjectSearchForm = payload;
			sessionStorage.quotationSubjectSearchForm = JSON.stringify(payload);
		},
		setCusotmerRejectReasonSearchForm(state, payload) {
			state.customerRejectReasonSearachForm = payload;
			sessionStorage.customerRejectReasonSearachForm = JSON.stringify(payload);
		},
		setAdminRejectReasonSearchForm(state, payload) {
			state.adminRejectReasonSearchForm = payload;
			sessionStorage.adminRejectReasonSearchForm = JSON.stringify(payload);
		},
		setRevertReasonSearchForm(state, payload) {
			state.revertReasonSearchForm = payload;
			sessionStorage.revertReasonSearchForm = JSON.stringify(payload);
		},
		invalidReasonSearchForm(state, payload) {
			state.invalidReasonSearchForm = payload;
			sessionStorage.invalidReasonSearchForm = JSON.stringify(payload);
		},
		setSpecialProductSearchForm(state, payload) {
			state.specialProductSearchForm = payload;
			sessionStorage.specialProductSearchForm = JSON.stringify(payload);
		},

		setSpecialProductRemarkSearchForm(state, payload) {
			state.specialProductRemarkSearchForm = payload;
			sessionStorage.specialProductRemarkSearchForm = JSON.stringify(payload);
		},
		setCatTypeCostSearchForm(state, payload) {
			state.catTypeCostSearchForm = payload;
			sessionStorage.catTypeCostSearchForm = JSON.stringify(payload);
		}
	}
};